import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import { errorIcon } from '../../assets/index.js';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext.js';
import { shouldNotSave } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const ZamerComponent18 = ({ urlPath, receivedToken }) => {
    const {intention, stepValue, stagesArr, id} = useContext(FormContext); 
    const [ focused, setFocused ] = useState({
        nazev: false,
    });
    const [request, setRequest] = useState({
        'applicationId': id,
        accompanyingDocument: {
            buildingPermit: true,
            frameworkPermit: false,
            changeOfUsage: false,
            executionOfConstruction: false,
            demolition: false,
            additionalDocumentation: false,
            passport: false,  
        },
        buildIntention: {
            title: { value: null, dirty: false },
        }
    });
    const buildIntention = request?.buildIntention;
    const accompanyingDocument = request?.accompanyingDocument;
    const prevRequest = usePrevious(request);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    // eslint-disable-next-line no-unused-vars
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const [loading, setLoading] = useState(false);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, receivedToken, pathname, navigate);
    
                if ((200 <= response?.status) && (response?.status < 300)) {
                    const intention = response.data?.buildIntention;
                    if (intention) {
                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value,
                            zamerType: intention?.eszId ? 'existing' : 'new'
                        }));
                    }

                    const accompanyingDocument = response.data?.accompanyingDocument;
                    if (accompanyingDocument) {
                        setRequest(state => ({
                            ...state, 
                            accompanyingDocument: {
                                ...state.accompanyingDocument,
                                buildingPermit: accompanyingDocument.buildingPermit ?? true,
                                frameworkPermit: accompanyingDocument.frameworkPermit ?? false,
                                changeOfUsage: accompanyingDocument.changeOfUsage ?? false,
                                executionOfConstruction: accompanyingDocument.executionOfConstruction ?? false,
                                demolition: accompanyingDocument.demolition ?? false,
                                additionalDocumentation: accompanyingDocument.additionalDocumentation ?? false,
                                passport: accompanyingDocument.passport ?? false,
                            },
                            ...(intention ? { buildIntention: {...state.buildIntention, title: intention.title} } : {})
                        }));
                    }
                    setLoading(false);
                    // return error obj from caught error.response obj
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        const updatedObj = {
            applicationId: request.applicationId,
            accompanyingDocument: request.accompanyingDocument,
        };
        await formSave(updatedObj, token, urlPath, source, receivedToken);
    };

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.accompanyingDocument, request.accompanyingDocument)) {
                return;
            }

            saveChanges();
        }
    }, [accompanyingDocument]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <form className='p-0'>

                        {/* Název záměru */}
                        <Row className='row-wrapper'>
                            <p className='title p-0 mb-3' style={{ color: (!buildIntention?.title?.value?.trim() && (error || focused.nazev)) ? '#C52A3A' : 'black' }}>Záměr</p>
                            <label className='mb-2 px-0'>
                                <Row className=''>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <input 
                                            disabled={true}
                                            type='text'
                                            required
                                            defaultValue={buildIntention?.title?.value ?? ''}
                                            style={{ border: error && !buildIntention?.title?.value?.trim() && 'solid 1px #C52A3A' }}
                                            {...(focused.nazev && { focused: 'true' })}
                                            onBlur={(e) => {
                                                setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, title: { ...state.buildIntention.title, value: e.target.value} } }));
                                                handleFocused('nazev');
                                            }}
                                            className='id-input2 bg-white p-2'
                                        />
                                        {(!buildIntention?.title?.value?.trim() && (error || focused.nazev)) &&
                                            <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                                Bez vyplnění názvu záměru není možné pokračovat.
                                            </Container>}
                                    </Col>
                                </Row>
                            </label>
                        </Row>

                    </form>

                    <Row className='row-wrapper'>
                        <p className='p-0 mb-2 section-title'>Druh dokumentace</p>
                        <p className='section-description p-0 mb-4'>Podle ustanovení § 1 vyhlášky č. 131/2024 o dokumentaci staveb. (dokumentace pro povolení stavby, dokumentace pro rámcové povolení, dokumentace pro povolení změny využití území, dokumentace pro provádění stavby, dokumentace pro odstranění stavby, pasportu stavby a doplnění dokumentace pro vydání rozhodnutí o umístění stavby o architektonicko-stavební řešení a stavebně konstrukční řešení)</p>
                        <form className='d-flex flex-column p-0'>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input 
                                    type='radio'
                                    name='documentationType'
                                    value='buildingPermit'
                                    className='radio'
                                    checked={request?.accompanyingDocument?.buildingPermit} 
                                    onChange={() => setRequest(state => ({ 
                                        ...state, 
                                        accompanyingDocument: {
                                            ...state.accompanyingDocument, 
                                            buildingPermit: true,
                                            frameworkPermit: false,
                                            changeOfUsage: false,
                                            executionOfConstruction: false,
                                            demolition: false,
                                            additionalDocumentation: false,
                                            passport: false,
                                        } }))} />
                                Dokumentace pro povolení stavby
                            </label>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input 
                                    type='radio'
                                    name='documentationType'
                                    value='frameworkPermit'
                                    className='radio'
                                    checked={request?.accompanyingDocument?.frameworkPermit} 
                                    onChange={() => setRequest(state => ({ 
                                        ...state, 
                                        accompanyingDocument: {
                                            ...state.accompanyingDocument, 
                                            buildingPermit: false,
                                            frameworkPermit: true,
                                            changeOfUsage: false,
                                            executionOfConstruction: false,
                                            demolition: false,
                                            additionalDocumentation: false,
                                            passport: false,
                                        } }))} />
                                Dokumentace pro rámcové povolení
                            </label>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input 
                                    type='radio'
                                    name='documentationType'
                                    value='changeOfUsage'
                                    className='radio'
                                    checked={request?.accompanyingDocument?.changeOfUsage} 
                                    onChange={() => setRequest(state => ({ 
                                        ...state, 
                                        accompanyingDocument: {
                                            ...state.accompanyingDocument, 
                                            buildingPermit: false,
                                            frameworkPermit: false,
                                            changeOfUsage: true,
                                            executionOfConstruction: false,
                                            demolition: false,
                                            additionalDocumentation: false,
                                            passport: false,
                                        } }))} />
                                Dokumentace pro povolení změny využití území
                            </label>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input 
                                    type='radio'
                                    name='documentationType'
                                    value='executionOfConstruction'
                                    className='radio'
                                    checked={request?.accompanyingDocument?.executionOfConstruction} 
                                    onChange={() => setRequest(state => ({ 
                                        ...state, 
                                        accompanyingDocument: {
                                            ...state.accompanyingDocument, 
                                            buildingPermit: false,
                                            frameworkPermit: false,
                                            changeOfUsage: false,
                                            executionOfConstruction: true,
                                            demolition: false,
                                            additionalDocumentation: false,
                                            passport: false,
                                        } }))} />
                                Dokumentace pro provádění stavby
                            </label>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input 
                                    type='radio'
                                    name='documentationType'
                                    value='demolition'
                                    className='radio'
                                    checked={request?.accompanyingDocument?.demolition} 
                                    onChange={() => setRequest(state => ({ 
                                        ...state, 
                                        accompanyingDocument: {
                                            ...state.accompanyingDocument, 
                                            buildingPermit: false,
                                            frameworkPermit: false,
                                            changeOfUsage: false,
                                            executionOfConstruction: false,
                                            demolition: true,
                                            additionalDocumentation: false,
                                            passport: false,
                                        } }))} />
                                Dokumentace pro odstranění stavby
                            </label>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input 
                                    type='radio'
                                    name='documentationType'
                                    value='additionalDocumentation'
                                    className='radio'
                                    checked={request?.accompanyingDocument?.additionalDocumentation} 
                                    onChange={() => setRequest(state => ({ 
                                        ...state, 
                                        accompanyingDocument: {
                                            ...state.accompanyingDocument, 
                                            buildingPermit: false,
                                            frameworkPermit: false,
                                            changeOfUsage: false,
                                            executionOfConstruction: false,
                                            demolition: false,
                                            additionalDocumentation: true,
                                            passport: false,
                                        } }))} />
                                Doplnění dokumentace pro vydání rozhodnutí o umístění stavby o architektonicko-stavební řešení a stavebně konstrukční řešení.
                            </label>
                            <label className='d-flex align-items-center label'>
                                <input 
                                    type='radio'
                                    name='documentationType'
                                    value='passport'
                                    className='radio'
                                    checked={request?.accompanyingDocument?.passport} 
                                    onChange={() => setRequest(state => ({ 
                                        ...state, 
                                        accompanyingDocument: {
                                            ...state.accompanyingDocument, 
                                            buildingPermit: false,
                                            frameworkPermit: false,
                                            changeOfUsage: false,
                                            executionOfConstruction: false,
                                            demolition: false,
                                            additionalDocumentation: false,
                                            passport: true,
                                        } }))} />
                                Pasport stavby
                            </label>
                        </form>
                    </Row>


                </Container>}
        </>
    );
};

ZamerComponent18.propTypes = {
    urlPath: PropTypes.string.isRequired,
    receivedToken: PropTypes.string,
};

export default ZamerComponent18;