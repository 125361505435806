import React, { useContext, useState, useEffect, useImperativeHandle } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/prijemci.css';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import '../../css/zamerComponent.css';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import PropTypes from 'prop-types';
import { formSave } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { SearchWithDataList } from '../index.js';
import { canFulFillPrijemceList, canInitiateSaveForPrijemce, getBuildApplicationFormForPrijemce, getPrijemceList } from '../../helperFunctions/helpers';
import { AFFECTED, AUTHORITY_MODEL } from '../../constants/sharedConstants';
import usePrevious from '../CustomHooks/usePrevious';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../CustomHooks/useDebounce.jsx';

const PrijemceComponent02 = ({ urlPath }) => {
    const { intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id } = useContext(FormContext); 
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [request, setRequest] = useState({
        'applicationId': id,
        buildIntention: {
            recipientAuthority: AUTHORITY_MODEL
        }
    });
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [prijemceList, setPrijemceList] = useState([]);
    const [searchRequest, setSearchRequest] = useState(
        {
            title: '',
            typeTitle: null
        }
    );
    const { token } = useContext(AuthContext);
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const debouncedSearchTitle = useDebounce(searchRequest.title);

    useEffect(() => {
        if(!id) {
            return;
        }
        getBuildApplicationFormForPrijemce(
            id,
            token,
            intention,
            setRequest,
            setSearchRequest,
            AFFECTED,
            'recipientAuthority',
            pathname,
            navigate
        );
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!canFulFillPrijemceList(request, firstRender, searchRequest, setPrijemceList, 'recipientAuthority')) {
            return;
        }

        setIsSearchLoading(true);
        getPrijemceList(searchRequest, token, setPrijemceList, setIsSearchLoading, signal);

        return () => {
            controller.abort();
        };
    }, [debouncedSearchTitle]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if(!canInitiateSaveForPrijemce(firstRender, request, prevRequest, 'recipientAuthority')) {
            return;
        }

        saveChanges();
        
    }, [request.buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (!request.buildIntention?.recipientAuthority || request.buildIntention?.recipientAuthority?.authorityName?.trim() === '')
            {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));


    const hasError = error && (!searchRequest?.value || !searchRequest);

    return (
        <Container className='' fluid>
            <Row className='mb-5'>
                <p className='p-0 main-title'>{`${stepValue}. Příjemce `}</p>
            </Row>
            <Row className='mb-4'>
                <p className='p-0 section-title'>Dotčený orgán</p>
            </Row>
            {/* <label className='mb-4 p-0 d-flex flex-column select-input'>
            Typ orgánu
                <Col xs={12} md={6}>
                    <select type='select'
                        style={{appearance: 'none', background: `url(${arrowDownBlue}) no-repeat right .8rem center`, padding: '.5rem 2rem .5rem .8rem', backgroundColor: 'white', borderRadius: '3px', border: 'solid 1px #DDDDDD'}}
                        className='id-input2 w-100'
                    >
                        <option value=''>vyberte</option>
                        <option value={'1'}>1</option>
                        <option value={'2'}>2</option>
                        <option value={'3'}>3</option>
                    </select>
                </Col>
            </label> */}
            <p className='p-0 title' style={{color: hasError && '#C52A3A'}}>Vyhledávání</p>
            <Row className='d-flex align-items-start'>
                <Col md={6} className='pe-md-4 mb-2 mb-md-0'> 
                    <Container fluid>
                        <SearchWithDataList
                            request={request}
                            authorityType={'recipientAuthority'}
                            authorityObj={request.buildIntention.recipientAuthority}
                            setRequest={setRequest}
                            paramFirst={'title'}
                            paramSecond={'typeTitle'}
                            setSearchRequest={setSearchRequest}
                            searchRequest={searchRequest} // obj
                            listData={prijemceList}
                            setListData={setPrijemceList} // arr
                            dynamicVal={AFFECTED} // str
                            isSearchLoading={isSearchLoading}
                            setIsSearchLoading={setIsSearchLoading}
                        />
                    </Container>
                </Col>
                <Col md={6} className='mt-md-2'>
                    <p className='input-help-text'>Vyhledejte dotčený orgán, pro který je žádost určena</p>
                </Col>
            </Row>
        </Container>
    );
};

PrijemceComponent02.propTypes = {
    urlPath: PropTypes.string.isRequired,
};


export default PrijemceComponent02;