import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import { shouldNotSave, useOnlyRequiredKeys } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { 
    WATER_PROTECTION, 
    MINERAL_BEARING_BEDS_PROTECTION, 
    PUBLIC_HEALTH_PROTECTION, 
    SPA_AND_SPRINGS_PROTECTION, 
    VETERINARY_CARE, 
    MONUMENT_CARE, 
    LAND_TRANSPORT, 
    RAIL_TRANSPORT, 
    AIR_TRANSPORT, 
    WATER_TRANSPORT, 
    ENERGY_INDUSTRY, 
    NUCLEAR_SAFETY_AND_IONIZING_RADIATION_PROTECTION, 
    ELECTRONIC_COMMUNICATIONS, 
    STATE_DEFENSE, 
    STATE_SECURITY, 
    CIVILIAN_PROTECTION, 
    FIRE_PROTECTION, 
    BUILDING_PERMIT_OR_ADDITIONAL_BUILDING_PERMIT, 
    PERMIT_MODIFICATION, 
    LAND_USE_CHANGE_PERMIT, 
    LAND_DIVISION_OR_CONSOLIDATION_PERMIT,  
    FRAMEWORK_PERMIT, 
    INTENTION_CHANGE_PERMIT_BEFORE_COMPLETION, 
    OCCUPANCY_PERMIT, 
    EARLY_OCCUPANCY_PERMIT, 
    TRIAL_OPERATION_PERMIT, 
    CHANGE_IN_USE_PERMIT, 
    DEMOLITION_PERMIT, 
    EXCEPTION, 
    OTHER_PROTECTION, 
    COORDINATED_OPINION_OR_COMMITTED_VIEW, 
    UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA, 
    UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA, 
    OPINION_OR_COMMITTED_VIEW, 
    INITIAL_OBJ, 
    LIFE_ENVIRONMENTAL_PROTECTION, 
    PROTECTIVE_ZONE_DETERMINATION,
    ADDITIONAL_PERMIT,
    IS_OTHER_PROTECTION,
} from '../../constants/form02';
import FormContext from '../../formContexts/FormContext';
import usePrevious from '../CustomHooks/usePrevious';
import { errorIcon } from '../../assets/index.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent = ({urlPath, setCheckbox}) => {
    
    const { intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id } = useContext(FormContext); 
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth <= 700);
    const { token } = useContext(AuthContext);
    const [ focused, setFocused ] = useState(false);
    const [ request, setRequest ] = useState({
        'applicationId': id,
        form: INITIAL_OBJ,
    });
    const previousRequest = usePrevious(request);
    const prevReqException = usePrevious(request.form[OPINION_OR_COMMITTED_VIEW]);
    const firstRender = useFirstRender();
    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {
                const loadedData = response?.data?.buildApplicationForms[0];

                if (loadedData) {
                    const formStructure = request.form;
                    const filteredDataToSave = useOnlyRequiredKeys(formStructure, loadedData);
                    setRequest(prev => ({
                        ...prev,
                        form: {
                            ...prev.form,
                            ...filteredDataToSave,
                            [COORDINATED_OPINION_OR_COMMITTED_VIEW]: 
                            loadedData[COORDINATED_OPINION_OR_COMMITTED_VIEW] ?? true,
                            [UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA]: 
                            loadedData[UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA] ?? false,
                            [UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA]: 
                            loadedData[UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA] ?? false,
                            [OPINION_OR_COMMITTED_VIEW]: 
                            loadedData[OPINION_OR_COMMITTED_VIEW] ?? false,
                            [EXCEPTION]: 
                            loadedData[EXCEPTION] ?? false,
                        }
                    }));
                }
            }
        } 
        )();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    useEffect(() => {
        if (request.form[IS_OTHER_PROTECTION] === null) {
            return;
        }

        if (!request.form[IS_OTHER_PROTECTION] && request.form[OPINION_OR_COMMITTED_VIEW]) {
            setRequest(prev => ({...prev, form: {...prev.form, [OTHER_PROTECTION]: '' }}));
        }

    }, [request.form[IS_OTHER_PROTECTION]]);
    
    useEffect(() => {
        if (shouldNotSave(previousRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form]);
        
    useEffect(() => {
        if (request.form?.[EXCEPTION] === null) {
            return;
        }

        setCheckbox({
            first: request.form[EXCEPTION] ?? false,
        });
    }, [request.form[EXCEPTION]]);

    useEffect(() => {  
        if (request.form[OPINION_OR_COMMITTED_VIEW] === false && prevReqException === true) { 
            setRequest(prev => ({...prev, form: {
                ...prev.form,  
                [IS_OTHER_PROTECTION]: false,
                [OTHER_PROTECTION]: '',
                [WATER_PROTECTION]: false,
                [LIFE_ENVIRONMENTAL_PROTECTION]: false,
                [MINERAL_BEARING_BEDS_PROTECTION]: false,
                [PUBLIC_HEALTH_PROTECTION]: false,
                [SPA_AND_SPRINGS_PROTECTION]: false,
                [VETERINARY_CARE]: false,
                [MONUMENT_CARE]: false,
                [LAND_TRANSPORT]: false,
                [RAIL_TRANSPORT]: false,
                [AIR_TRANSPORT]: false,
                [WATER_TRANSPORT]: false,
                [ENERGY_INDUSTRY]: false,
                [NUCLEAR_SAFETY_AND_IONIZING_RADIATION_PROTECTION]: false,
                [ELECTRONIC_COMMUNICATIONS]: false,
                [STATE_DEFENSE]: false,
                [STATE_SECURITY]: false,
                [CIVILIAN_PROTECTION]: false,
                [FIRE_PROTECTION]: false,
            }}));
            
        }
    }, [request.form[OPINION_OR_COMMITTED_VIEW]]);

    const handleRadioCheck = (e) => {
        const value = e.target.value;
        switch (value) {
        case COORDINATED_OPINION_OR_COMMITTED_VIEW:
            setRequest(prev => ({
                ...prev,
                form: {
                    ...prev.form,
                    [COORDINATED_OPINION_OR_COMMITTED_VIEW]: true,
                    [UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA]: false,
                    [UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA]: false,
                    [OPINION_OR_COMMITTED_VIEW]: false,
                }
            }));
            break;
        case UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA:
            setRequest(prev => ({
                ...prev,
                form: {
                    ...prev.form,
                    [COORDINATED_OPINION_OR_COMMITTED_VIEW]: false,
                    [UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA]: true,
                    [UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA]: false,
                    [OPINION_OR_COMMITTED_VIEW]: false,
                }
            }));
            break;
        case UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA:
            setRequest(prev => ({
                ...prev,
                form: {
                    ...prev.form,
                    [COORDINATED_OPINION_OR_COMMITTED_VIEW]: false,
                    [UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA]: false,
                    [UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA]: true,
                    [OPINION_OR_COMMITTED_VIEW]: false,
                }
            }));
            break;
        case OPINION_OR_COMMITTED_VIEW:
            setRequest(prev => ({
                ...prev,
                form: {
                    ...prev.form,
                    [COORDINATED_OPINION_OR_COMMITTED_VIEW]: false,
                    [UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA]: false,
                    [UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA]: false,
                    [OPINION_OR_COMMITTED_VIEW]: true
                }
            }));
            break;
        default:
            console.log('invalid');
            break;
        }
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            // if (request.form[OPINION_OR_COMMITTED_VIEW] && (checked.hledisko <= 0 || checked.dotcenyOrgan <= 0))
            // {
            //     err = true;
            // }
            if (request.form?.[IS_OTHER_PROTECTION] && (!request?.form?.[OTHER_PROTECTION] || request?.form?.[OTHER_PROTECTION]?.trim() === ''))
            {
                err = true;
            }
        
            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const handleFocused = () => {
        setFocused(true);
    };

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            <Row className='pb-4'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>
            <Row className='pb-3'>
                <Row className='py-4'>
                    <p className='p-0 title secondary'>Žádám o</p>
                </Row>
                <Row className='py-2'>
                    <Row className='d-flex flex-column'>
                        <label className={`ls-1p25 p-0 pb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                            <input
                                className='me-3'
                                type="radio"
                                checked={request.form[COORDINATED_OPINION_OR_COMMITTED_VIEW]}
                                name={COORDINATED_OPINION_OR_COMMITTED_VIEW}
                                value={COORDINATED_OPINION_OR_COMMITTED_VIEW}
                                onChange={(e)=> handleRadioCheck(e)}
                            />
                                koordinované vyjádření nebo koordinované závazné stanovisko, včetně jednotného environmentálního stanoviska, jehož součástí není stanovisko k posouzení vlivu záměru na životní prostředí (EIA)
                        </label>
                        <label className={`ls-1p25 p-0 pb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                            <input
                                className='me-3'
                                type="radio"
                                checked={request.form[UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA]} 
                                name={UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA}
                                value={UNIFIED_ENVIRONMENTAL_OPINION_EXCLUDING_EIA} 
                                onChange={(e)=> handleRadioCheck(e)}
                            />
                                jednotné environmentální stanovisko, jehož součástí není stanovisko EIA
                        </label>
                        <label className={`ls-1p25 p-0 pb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                            <input
                                className='me-3'
                                type="radio"
                                checked={request.form[UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA]} 
                                name={UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA}
                                value={UNIFIED_ENVIRONMENTAL_OPINION_INCLUDING_EIA} 
                                onChange={(e)=> handleRadioCheck(e)}
                            />
                                jednotné environmentální stanovisko, včetně stanoviska EIA
                        </label>
                        <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                            <input
                                className='me-3'
                                type="radio"
                                checked={request.form[OPINION_OR_COMMITTED_VIEW]} 
                                name={OPINION_OR_COMMITTED_VIEW}
                                value={OPINION_OR_COMMITTED_VIEW} 
                                onChange={(e)=> handleRadioCheck(e)}
                            />
                                vyjádření nebo závazné stanovisko
                        </label>
                    </Row>
                </Row>
            </Row>
            {(request.form[OPINION_OR_COMMITTED_VIEW]) && <>
                <Row className='py-3 pb-4'>
                    <p className='p-0 title third'>Vyjádření nebo závazné stanovisko z hlediska:</p>
                </Row>
                <Row className='py-2'>
                    <Row className='d-flex row'>                    
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[WATER_PROTECTION]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [WATER_PROTECTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                ochrany vod, není-li součástí jednotného environmentálního stanoviska    
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[LIFE_ENVIRONMENTAL_PROTECTION]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [LIFE_ENVIRONMENTAL_PROTECTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                ochrany životního prostředí, není-li součástí jednotného environmentálního stanoviska              
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[MINERAL_BEARING_BEDS_PROTECTION]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [MINERAL_BEARING_BEDS_PROTECTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                ochrany ložisek nerostných surovin             
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[PUBLIC_HEALTH_PROTECTION]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [PUBLIC_HEALTH_PROTECTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                ochrany veřejného zdraví               
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[SPA_AND_SPRINGS_PROTECTION]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [SPA_AND_SPRINGS_PROTECTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                lázní a zřídel                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[VETERINARY_CARE]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [VETERINARY_CARE]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                veterinární péče               
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[MONUMENT_CARE]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [MONUMENT_CARE]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                památkové péče                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[LAND_TRANSPORT]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [LAND_TRANSPORT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                dopravy na pozemních komunikacích                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[RAIL_TRANSPORT]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [RAIL_TRANSPORT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                dopravy drážní                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[AIR_TRANSPORT]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [AIR_TRANSPORT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                dopravy letecké                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[WATER_TRANSPORT]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [WATER_TRANSPORT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                dopravy vodní                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[ENERGY_INDUSTRY]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [ENERGY_INDUSTRY]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                energetiky                
                        </label>                  
                        <label className={`ls-1p25 p-0 pb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[NUCLEAR_SAFETY_AND_IONIZING_RADIATION_PROTECTION]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [NUCLEAR_SAFETY_AND_IONIZING_RADIATION_PROTECTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                jaderné bezpečnosti a ochrany před ionizujícím zářením                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[ELECTRONIC_COMMUNICATIONS]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [ELECTRONIC_COMMUNICATIONS]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                elektronických komunikací                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[STATE_DEFENSE]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [STATE_DEFENSE]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                obrany státu                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[STATE_SECURITY]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [STATE_SECURITY]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                bezpečnosti státu                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[CIVILIAN_PROTECTION]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [CIVILIAN_PROTECTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                ochrany obyvatelstva                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[FIRE_PROTECTION]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [FIRE_PROTECTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                požární ochrany                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[IS_OTHER_PROTECTION]}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [IS_OTHER_PROTECTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                jiné                
                        </label>
                    </Row>
                    {request.form[IS_OTHER_PROTECTION]
                        &&
                    <label className='mt-2 px-0' style={{ color: (error && !request?.form?.[OTHER_PROTECTION]) || (focused && !request?.form?.[OTHER_PROTECTION]) ? '#C52A3A' : '' }}>
                        Jiné hledisko  
                        <Row>
                            <Col xs={12} md={6} className='d-flex flex-column other-input-label'>
                                <input
                                    required
                                    style={{ border: error && !request?.form?.[OTHER_PROTECTION] && 'solid #C52A3A 1px' }}
                                    type="input"
                                    {...(focused && {focused: 'true'})}
                                    defaultValue={request.form[OTHER_PROTECTION]}
                                    onBlur={(e) => {
                                        setRequest(prev => ({...prev, form: {...prev.form, [OTHER_PROTECTION]: e.target.value }}));
                                        handleFocused();
                                    }}
                                    className='id-input2 bg-white'
                                />
                            </Col>
                        </Row>
                    </label>
                    }
                    {request.form[IS_OTHER_PROTECTION] && ((error && request?.form?.[OTHER_PROTECTION]?.trim() === '') || (focused && !request?.form?.[OTHER_PROTECTION])) && <Col xs={{ span: 12, order: 2 }}>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění tohoto pole není možné pokračovat.</p>
                    </Col>}
                </Row>
            </>}
            <>
                <Row className='py-4 mt-2'>
                    <p className='p-0 title third'>Vyjádření nebo závazné stanovisko dotčeného orgánu bude podkladem pro:</p>
                </Row>
                <Row className='py-2'>
                    <Row className='d-flex row'>                    
                        <label className={`ls-1p25 p-0 pb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[BUILDING_PERMIT_OR_ADDITIONAL_BUILDING_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [BUILDING_PERMIT_OR_ADDITIONAL_BUILDING_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                povolení stavby nebo zařízení (popř. změny dokončené stavby)             
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[PERMIT_MODIFICATION] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [PERMIT_MODIFICATION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                změnu povolení             
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[LAND_USE_CHANGE_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [LAND_USE_CHANGE_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                povolení změny využití území              
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[LAND_DIVISION_OR_CONSOLIDATION_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [LAND_DIVISION_OR_CONSOLIDATION_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                povolení dělení nebo scelení pozemků                
                        </label>
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[FRAMEWORK_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [FRAMEWORK_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                rámcové povolení
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[INTENTION_CHANGE_PERMIT_BEFORE_COMPLETION] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [INTENTION_CHANGE_PERMIT_BEFORE_COMPLETION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                povolení změny záměru před dokončením
                        </label>
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[PROTECTIVE_ZONE_DETERMINATION] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [PROTECTIVE_ZONE_DETERMINATION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                stanovení ochranného pásma           
                        </label>
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[OCCUPANCY_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [OCCUPANCY_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                kolaudační rozhodnutí                
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[EARLY_OCCUPANCY_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [EARLY_OCCUPANCY_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                povolení předčasného užívání stavby         
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[TRIAL_OPERATION_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [TRIAL_OPERATION_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                povolení zkušebního provozu               
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[CHANGE_IN_USE_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [CHANGE_IN_USE_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                povolení změny v užívání stavby       
                        </label>                  
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start w-50'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[DEMOLITION_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [DEMOLITION_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                povolení odstranění stavby, zařízení a terénních úprav              
                        </label>                                   
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[ADDITIONAL_PERMIT] }
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [ADDITIONAL_PERMIT]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, dotcenyOrgan: prev.dotcenyOrgan - 1}));
                                    // }
                                }}
                            />
                                dodatečné povolení stavby, zařízení, terénních úprav              
                        </label>
                        {/* {error && checked.dotcenyOrgan <= 0 && !request.form.buildingAuthority && <Col xs={{ span: 12, order: 2 }} className='mt-1'>
                            <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vybrání pole není možné pokračovat.</p>
                        </Col>}                                   */}
                    </Row>
                    <Row className='py-3'>
                        <p className='px-0 py-2 title third'>Výjimka</p>
                    </Row>
                    <Row>
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start w-50'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={request.form[EXCEPTION] ?? false}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, [EXCEPTION]: e.target.checked }}));
                                    // if(e.target.checked)
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko + 1}));
                                    // }
                                    // else
                                    // {
                                    //     setChecked(prev => ({...prev, hledisko: prev.hledisko - 1}));
                                    // }
                                }}
                            />
                                Součástí žádosti o povolení stavby bude žádost o povolení výjimky                
                        </label>   
                    </Row>
                </Row>
            </>
        </Container>
    );
};

RozsahComponent.propTypes = {
    
    urlPath: PropTypes.string,
    setCheckbox: PropTypes.func,
};

export default RozsahComponent;