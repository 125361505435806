import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/dokumentace.css';
import { DokumentaceRadioControls, FileUpload, UploadedAttachments } from '../index.js';
import { shouldNotSave, spreadObject, handleFiles, handleSpreadUploadedDocuments, getOngoingUploadsForDokumentace } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls.js';
import FormContext from '../../formContexts/FormContext.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { infoCircle } from '../../assets/index.js';
import { DOCUMENT_FOURTH } from '../../constants/sharedConstants.js';
import { useLocation, useNavigate } from 'react-router-dom';

const DokumentaceComponent14 = ({progressElements, setProgressElements, urlPath, documents, setDocuments}) => {
    const {intention, stepValue, saveFormRef, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState(
        {
            'applicationId': id,
            'buildApplication': {
                'documentations': []
            },
            'buildIntention': {
                'documentUploadHere': null,
                'documentPaperForm': null,
                'documentPreviousVersion' : null,
            },
        }
    );
    const onGoingUploads = getOngoingUploadsForDokumentace(progressElements);
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();
    const { setUploadedBytes, setUploadError, uploadError } = useContext(FormContext); 
    const allDocuments = Object.values(documents).flat();
    const documentsToUpdate = [...allDocuments].filter(fileObj => 
        (!fileObj.blobFile && !fileObj.abortController && !fileObj.progress && !fileObj.message));
    const existingFilesToUpdate = documentsToUpdate.filter(el => el.existing);
    const newFilesToUpdate = documentsToUpdate.filter(el => !el.existing);

    const saveChanges = async (manualReq = null) => {
        const source = axios.CancelToken.source();
        await formSave(manualReq ?? request, token, urlPath, source);
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const loadedIntention = response.data?.buildIntention;

                    if (loadedIntention) {
                        setRequest(prev => ({...prev, buildIntention: 
                            {...spreadObject(loadedIntention), 
                                documentUploadHere: !loadedIntention?.eszId ? (loadedIntention.documentUploadHere ?? true) : Boolean(loadedIntention.documentUploadHere), 
                                documentPaperForm: loadedIntention.documentPaperForm ?? false,
                                documentPreviousVersion: (loadedIntention?.eszId && !loadedIntention.documentUploadHere && !loadedIntention.documentPaperForm) && (loadedIntention.documentPreviousVersion ?? true),
                            }}));
                    }                   

                    const loadedData = response.data.buildApplication;
                    const documents = loadedData?.documentations;
                    handleSpreadUploadedDocuments([...(documents || []), ...onGoingUploads], loadedIntention, setDocuments, setUploadedBytes);                    
                } else {
                    console.log('ID NOT YET IN DB');
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);
    
    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        if (!(request?.buildIntention?.id)) {
            return;
        }
        saveChanges();
    }, [request.buildApplication.documentations]);

    useEffect(() => {
        if (newFilesToUpdate.length >= 50000) {
            setUploadError(prev => [...prev, {
                where: null,
                why: 'total_amount',
                uid: null,
            }]);   
            return;
        } else if (newFilesToUpdate.length < 50000 && uploadError.find(el => el.why === 'total_amount')) {
            setUploadError(prev => [...prev].filter(el => el.why !== 'total_amount')); 
        }

        setRequest(prev => ({ ...prev, buildApplication: { ...prev.buildApplication, documentations: [...documentsToUpdate].map(file => {
            // eslint-disable-next-line no-unused-vars
            const {flagged, existing, ...rest} = file;
            return {...rest};
        }) } }));
    }, [existingFilesToUpdate.length, newFilesToUpdate.length]);

    const handleFileInput = async (files, category) => {
        if (category !== DOCUMENT_FOURTH) {
            return;
        }

        if (uploadError.find(el => el.why === 'total_amount')) {
            return;
        }
        handleFiles(files, category, token, setDocuments, 'documents');
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    return (
        <Container className='d-flex row align-items-center dokumentace-container' fluid>
            <Row className='row-wrapper'>
                <p className='p-0 pb-4 title primary'>{`${stepValue}. Dokumentace`}</p>
                <span className='dokumentace-text fst-italic p-0'>Dokumentace stavby s vyznačením změn vyvolaných změnou v užívání oproti ověřené dokumentaci k povolené stavbě podle vyhlášky o dokumentaci staveb</span>
            </Row>
            <Row className='row-wrapper'>
                <DokumentaceRadioControls
                    documents={documents} 
                    setDocuments={setDocuments} 
                    setUploadedBytes={setUploadedBytes} 
                    saveChanges={saveChanges} 
                    setRequest={setRequest}
                    request={request}
                    hasInfo={true}
                />
                <p className='p-0 dokumentace-help_text'><img src={infoCircle} alt="infoCircle" className='me-2'/>Datový limit dokumentace je 5 GB.</p>
            </Row>
            {!request.buildIntention.documentPaperForm&& <>
                <Container className='pb-5'>
                    <Row className='pb-3'>
                        <p className='p-0 title secondary'>D — Dokumentace objektů</p>
                    </Row>
                    <Row>
                        <span className='dokumentace-text fst-italic p-0'>
                    Dokumentace se zpracovává po objektech a souborech technických nebo technologických zařízení. Dokumentace každého objektu se zpracovává přiměřeně podle požadavků stanovených v dokumentaci pro povolení záměru příslušného objektu.
                        </span>
                    </Row>
                    <UploadedAttachments
                        category={DOCUMENT_FOURTH}
                        attachments={documents[DOCUMENT_FOURTH]}
                        allDocuments={documents}
                        urlPath={urlPath}
                        setUploads={setDocuments}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                    />                    
                    <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_FOURTH} progressElements={progressElements}/>
                </Container>
            </>}
        </Container>
    );
};

DokumentaceComponent14.propTypes = {
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
    documents: PropTypes.object,
    setDocuments: PropTypes.func,
};

export default DokumentaceComponent14;