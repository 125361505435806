import React, { useContext, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { closeErrorPanelRed, deleteFile, file, iconChange, reloadIcon, uploadedFile } from '../../assets/index.js';
import { Progress } from '../index.js';
import PropTypes from 'prop-types';
import useUpload from '../CustomHooks/useUpload.jsx';
import { cancelUpload, canReupload, convertBytes, getNewAbortController, getTextfromSectionName, handleDeleteUpload, handleSetUpload, shouldNotFetch, shouldShowProgress } from '../../helperFunctions/helpers.js';
import { FORM_18_INTENTION, OTHER_DOCUMENTS, PLNA_MOC_FIFTH, PLNA_MOC_FOURTH, PLNA_MOC_SECOND, PLNA_MOC_THIRD, POWER_OF_ATTORNEY_FILE_MODEL, WARRANT } from '../../constants/sharedConstants.js';
import FormContext from '../../formContexts/FormContext.js';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config.js';
import { AuthContext } from 'react-oauth2-code-pkce';

const UploadedAttachmentElement = (
    { 
        fileObject, 
        isNotLastLine, 
        category,
        setUploads,
        isNew,
        progressElements,
        setProgressElements,    
        stepsRef,
        setCurrentApplicant,
        applicantArr,
        setFoundApplicantIndex,
        setAttorneyRequest,
        allDocuments,
        urlPath,
    }
) => {
    const forCsvImport = category.startsWith('csv_import');
    const { uid, fileName, url, size, message, section, subSection, isDeletable, abortController: abortControllerToTractForDokumentace } = fileObject;
    const [abortController, setAbortController] = useState(getNewAbortController());
    const abortSignal = abortControllerToTractForDokumentace?.signal || abortController.signal;
    const foundFile = [...progressElements].find(el => el.uid === uid);
    const respectiveProgress = foundFile?.progress ?? null;
    const { uploadError: uploadErrorsFromContext, setUploadError, setUploadedBytes, intention, uploadedBytes, id } = useContext(FormContext);
    const { token } = useContext(AuthContext);
    const { upload, uploadSuccess, uploadError, uploadProgress, uploadCancelled } = useUpload({
        forCsvImport, ...(abortControllerToTractForDokumentace && {setProgressElements, setUploads, allDocuments, token, id, setUploadedBytes, urlPath})
    });
    const isUploaded = respectiveProgress === null || respectiveProgress === 100 || uploadSuccess || foundFile?.success;
    const canDeleteFile = Boolean(isDeletable);
    const errorItem = [...uploadErrorsFromContext].find(el => el.uid === uid) ?? null;
    const formatError = errorItem?.why === 'format';
    const isForm18 = intention === FORM_18_INTENTION.title;
    const isForPOA = category.startsWith('power_of_attorney');
    const isFromModal = (category === PLNA_MOC_SECOND || category === PLNA_MOC_FIFTH);
    const dynamicKey = {
        personProp: (category === PLNA_MOC_THIRD || category === PLNA_MOC_FIFTH) ? 'applicant' : 'attorney',
        fileProp: (category === PLNA_MOC_THIRD || category === PLNA_MOC_FOURTH || category === PLNA_MOC_FIFTH) ? 'powerOfAttorneyRepresentative' : 'powerOfAttorneyFile'
    };
    
    const jumpBackToZadatele = () => {
        if (category !== WARRANT) {
            return;
        }

        if (!stepsRef || !applicantArr || !setFoundApplicantIndex) {
            return;
        }

        stepsRef?.current[2].click();
        // TODO find a better solution (maybe return a promise)
        setTimeout(() => {
            const foundZadatelIndex = applicantArr.findIndex(el => el.powerOfAttorneyFile.uid === uid);
            const foundZadatelIndexNew = applicantArr.findIndex(el => el.powerOfAttorneyRepresentative.uid === uid);

            if(foundZadatelIndex >= 0) {
                setFoundApplicantIndex(foundZadatelIndex);
            }

            if(foundZadatelIndexNew >= 0) {
                setFoundApplicantIndex(foundZadatelIndexNew);
            }

            if(foundZadatelIndex === -1 && foundZadatelIndexNew === -1) {
                setFoundApplicantIndex(-1);
            }
        }, [500]);
    };

    useEffect(() => {
        if (abortControllerToTractForDokumentace) {
            return;
        }

        if (progressElements.length === 0 || uploadProgress === null) {
            return;
        }
        
        if (foundFile) {
            setProgressElements(prev => [...prev].map(el => {
                if (el.uid === uid) {
                    return {...el, progress: uploadProgress};
                } else {
                    return el;
                }
            }));
        }
    }, [uploadProgress]);
    
    useEffect(() => {
        if (shouldNotFetch(uploadSuccess, uploadCancelled, uploadError, message)) {
            setUploadError(prev => [...prev, {
                where: section,
                why: message,
                uid,
            }]);              
            return;
        }

        if (isNew) {
            (async() => {
                if (forCsvImport) {
                    await upload();
                    return;
                }

                await upload(
                    url,
                    fileObject,
                    abortSignal,
                );
            })();
        }
    }, []);

    useEffect(() => {
        if (uploadSuccess) {
            if (forCsvImport) {
                return;
            }

            if (!abortControllerToTractForDokumentace) {
                handleSetUpload(setUploads, category, uid);

                if (getTextfromSectionName(category)) {
                    setUploadedBytes(prev => Number(prev) + Number(size));
                }
            }

            if (isForPOA) {
                const poaFile = {
                    uid,
                    fileName,
                    url,
                    section: category,
                    subSection,
                    size,
                    isDeletable,
                };
    
                if (setAttorneyRequest) {
                    setAttorneyRequest(prev => ({...prev, form: 
                        {...prev.form, [dynamicKey.personProp]: 
                            {...prev.form?.[dynamicKey.personProp], 
                                [dynamicKey.fileProp]:  
                                {...poaFile, personId: prev.form?.[dynamicKey.personProp]?.id ?? null}
                            }
                        }}));
                    return;
                }
            }
            return;
        }

        if (uploadCancelled) {
            if (forCsvImport) {
                return;
            }
            
            if (!abortControllerToTractForDokumentace) {
                handleDeleteUpload(setUploads, category, uid);
                handleDeleteUpload(setProgressElements, undefined, uid, 'forProgress');
            }

            if (isForPOA) {
                if (isFromModal && setCurrentApplicant) {
                    setCurrentApplicant(prev => ({...prev, [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}));
                    setAbortController(getNewAbortController());
                    return;
                }

                if (setAttorneyRequest) {
                    setAttorneyRequest(prev => ({...prev, form: 
                        {...prev.form, [dynamicKey.personProp]: 
                            {...prev.form?.[dynamicKey.personProp], 
                                [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}
                        }
                    }));
                    setAbortController(getNewAbortController());
                    return;
                }
            }
            return;
        }

        if (uploadError) {
            if (forCsvImport) {
                return;
            }

            if (isForPOA) {
                if (isFromModal && setCurrentApplicant) {
                    setCurrentApplicant(prev => ({...prev, [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}));
                    setAbortController(getNewAbortController());
                    return;
                }
    
                if (setAttorneyRequest) {
                    setAttorneyRequest(prev => ({...prev, form: 
                        {...prev.form, [dynamicKey.personProp]: 
                            {...prev.form?.[dynamicKey.personProp], 
                                [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}
                        }
                    }));
                    setAbortController(getNewAbortController());
                    return;
                }
            }
            return;
        }

    }, [uploadSuccess, uploadCancelled, uploadError]);

    const handleRetry = async () => {
        if (!canReupload(
            errorItem,
            size,
            uploadedBytes,
            setUploadError,
            uid,
            setUploads,
            category,
            progressElements,
            setProgressElements)) {
            return;
        }
        
        await upload(
            url,
            fileObject,
            abortSignal,
        );
    };

    const handleRemove = (id) => {
        handleDeleteUpload(setUploads, category, id);
        handleDeleteUpload(setProgressElements, undefined, id, 'forProgress');

        if (getTextfromSectionName(category)) {
            setUploadedBytes(prev => Number(prev) - Number(size));
        }

        if (isForPOA) {
            if (isFromModal && setCurrentApplicant) {
                setCurrentApplicant(prev => ({...prev, [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}));
                return;
            }

            if (setAttorneyRequest) {
                setAttorneyRequest(prev => ({...prev, 
                    form: {...prev.form, 
                        [dynamicKey.personProp]: 
                        {...prev.form?.[dynamicKey.personProp], 
                            [dynamicKey.fileProp]: POWER_OF_ATTORNEY_FILE_MODEL}
                    }
                }));
            }
        }
    }; 

    const downloadPdf = async (uid) => {
        try {
            const source = axios.CancelToken.source();

            const response = await axios.get(
                urlJoin(apiConfig.validateAndPersistBaseUrl, `preview-pdf/attachment/${uid}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                });

            if ((200 <= response?.status) && (response?.status < 300)) {
                window.open(URL.createObjectURL(response.data));
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
        }
    };
    
    return (fileName && <Row className={`${(message !== undefined || uploadError) ? '' : 'px-3'}`}>
        <Container 
            className={`d-flex align-items-center justify-content-between py-3 ${isNotLastLine ? 'border-bottom' : ''} file-container ${(message !== undefined || uploadError) ? 'error' : ''}`} fluid>
            <Row>
                <Container
                    className={`d-flex align-items-center justify-content-between file-name ${errorItem ? 'disabled' : ''}`}
                    onClick={() =>{ if(isUploaded && errorItem === null) downloadPdf(uid);}}
                >
                    <img src={uploadedFile} alt={fileName} />
                    <span className={`ps-2 file-description ${uploadError ? 'error' : ''}`}>
                        {`${fileName} - ${convertBytes(size)}`}
                    </span>
                </Container>
            </Row>
            <Row>
                {(message !== undefined) ? 
                    (<Container className={`d-flex align-items-center justify-content-end retry-btn-container ${formatError ? 'disabled' : ''}`} 
                        onClick={() => handleRetry()}>
                        <span className={`${formatError ? 'pe-0' : 'pe-2'} delete-btn-text error`}>{formatError ? 'Nepodporovaný formát souboru, nelze vložit.' : 'Chyba nahrávání, prosím zkuste znovu.'}</span>
                        <img 
                            className={`${formatError ? 'close-error-btn' : ''}`}
                            src={formatError ? closeErrorPanelRed : reloadIcon} 
                            alt={formatError ? 'zavřít' : `nahrát znova ${fileName}`}
                            onClick={() => {
                                if (formatError) {
                                    handleDeleteUpload(setUploads, category, uid);
                                    handleDeleteUpload(setProgressElements, undefined, uid, 'forProgress');
                                    setUploadError(prev => [...prev].filter(el => el.uid !== uid));              
                                } }}
                        />
                    </Container>)
                    : (category === OTHER_DOCUMENTS && subSection?.length > 0) ? 
                        ((<Container className='d-flex align-items-center delete-btn-container' 
                            onClick={() => downloadPdf(uid)}>
                            <Row>
                                <button type='button' className='d-flex justify-content-center align-items-center kontrola-button small'>
                                    <img src={file} alt="file" className='kontrola-button-icon' />
                                    Náhled
                                </button>
                            </Row>
                        </Container>))
                        : (isUploaded)  ?
                            (((canDeleteFile && category !== WARRANT) && 
                                ((!isForm18 && section !== 'cover_letter') || 
                                (isForm18 && canDeleteFile))) || (forCsvImport)
                                ? 
                                (<Container className='d-flex align-items-center delete-btn-container' 
                                    onClick={() => handleRemove(uid)}>
                                    <img src={deleteFile} alt={`smazat ${fileName}`}/>
                                    <span className='ps-2 delete-btn-text'>Smazat</span>
                                </Container>) 
                                : (category === WARRANT) ? 
                                    (<Container className='d-flex align-items-center delete-btn-container' 
                                        onClick={() => jumpBackToZadatele()}>
                                        <img src={iconChange} alt={'Upravit žadatele'}/>
                                        <span className='ps-2 delete-btn-text'>Upravit žadatele</span>
                                    </Container>) 
                                    : '') 
                            : (uploadError) ? 
                                (<Container className='d-flex align-items-center justify-content-end retry-btn-container' 
                                    onClick={() => handleRetry()}>
                                    <span className='pe-2 delete-btn-text error'>Chyba nahrávání, prosím zkuste znovu.</span>
                                    <img src={reloadIcon} alt={`nahrát znova ${fileName}`}/>
                                </Container>) 
                                : (!uploadSuccess && (abortControllerToTractForDokumentace ? respectiveProgress < 100 : uploadProgress < 100)) ? 
                                    (<Container className='d-flex align-items-center cancel-btn-container' 
                                        onClick={() => {
                                            cancelUpload(abortControllerToTractForDokumentace || abortController);
                                        }}>
                                        <span className='ps-2 delete-btn-text'>Zrušit</span>
                                    </Container>) 
                                    : ''}
            </Row>
        </Container>
        {shouldShowProgress(uploadSuccess, uploadCancelled, uploadError, abortControllerToTractForDokumentace ? respectiveProgress : uploadProgress) && <Progress progress={respectiveProgress}/>}
    </Row>
    );
};

UploadedAttachmentElement.propTypes = {
    fileObject: PropTypes.shape({
        uid: PropTypes.string,
        blobFile: PropTypes.object,
        fileName: PropTypes.string, 
        url: PropTypes.string,
        size: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        message: PropTypes.string,
        section: PropTypes.string,
        subSection: PropTypes.string,
        isDeletable: PropTypes.bool,
        abortController: PropTypes.any,
    }),
    isNotLastLine: PropTypes.bool,
    category: PropTypes.string,
    setUploads: PropTypes.func,
    isNew: PropTypes.bool,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    stepsRef: PropTypes.objectOf(Array),
    applicantArr: PropTypes.arrayOf(Object),
    setCurrentApplicant: PropTypes.func,
    setFoundApplicantIndex: PropTypes.func,
    setAttorneyRequest: PropTypes.func,
    allDocuments: PropTypes.object,
    urlPath: PropTypes.string,
};

export default UploadedAttachmentElement;
