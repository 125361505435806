import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { canFulFillPrijemceList, getPrijemceList, shouldNotSave, spreadObject, checkLimitAndInsertText } from '../../helperFunctions/helpers';
import {dateValidation, validDateBeforeOrEquals} from '../../helperFunctions/validationHelpers.js';
import { AUTHORITY_MODEL, BUILDING } from '../../constants/sharedConstants';
import usePrevious from '../CustomHooks/usePrevious';
import { SearchWithDataList } from '../index.js';
import useFirstRender from '../CustomHooks/useFirstRender';
import { CharCount } from '../../components/index.js';
import { errorIcon } from '../../assets/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../CustomHooks/useDebounce.jsx';

const ZmenaPovoleniComponent05 = ({urlPath}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext);
    const { token } = useContext(AuthContext);
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const maxDate = new Date();
    const [isDateError, setIsDateError] = useState({
        authorityDate: false,
        authorityDateFuture: false,
    });
    const handleErrors = (errorElement, hasError) => {
        setIsDateError(prev => ({...prev, [errorElement]: hasError}));
    };
    const [ focused, setFocused ] = useState({
        permissionRefNumber: false,
        permissionDated: false,
        changeDescription: false,
    });
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            changeDescription: '',
        },
        buildIntention: {
            permissionAuthority: AUTHORITY_MODEL,
            permissionRefNumber: '',
            permissionDated: '',
            verifiedDocumentationId: '',
        }
    });
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const [searchRequest, setSearchRequest] = useState(
        {
            title: '',
            typeTitle: null
        }
    );
    const [permAuthList, setPermAuthList] = useState([]);
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const buildIntention = request?.buildIntention;
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
    });
    const debouncedSearchTitle = useDebounce(searchRequest.title);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!canFulFillPrijemceList(request, firstRender, searchRequest, setPermAuthList, 'permissionAuthority')) {
            return;
        }

        setIsSearchLoading(true);
        getPrijemceList(searchRequest, token, setPermAuthList, setIsSearchLoading, signal);

        return () => {
            controller.abort();
        };
    }, [debouncedSearchTitle]);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const intention = response?.data?.buildIntention;

                if (intention) {
                    setRequest(state => ({ ...state, buildIntention: spreadObject(intention) }));
                    
                    if (intention?.permissionAuthority && intention?.permissionAuthority?.authorityName) {
                        setSearchRequest(prev => ({...prev, title: intention?.permissionAuthority?.authorityName ?? '', typeTitle: BUILDING }));
                    }  
                }

                const loadedDataForm = response?.data?.buildApplicationForms[0];
                if (loadedDataForm?.changeDescription) {
                    setRequest(state => ({ ...state, form: {...state.form, changeDescription: loadedDataForm?.changeDescription } }));
                }  
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    const hasError3 = (!buildIntention?.permissionDated?.trim() && (error || focused.permissionDated));
    const hasError4 = (buildIntention?.permissionDated?.trim() && (!validDateBeforeOrEquals(buildIntention?.permissionDated,maxDate)));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
 
            if (!buildIntention?.permissionRefNumber?.trim()
                || !buildIntention?.permissionDated?.trim()
                || !request?.form?.changeDescription?.trim()
                || !buildIntention?.permissionAuthority?.authorityName?.trim())
            {
                err = true;
            } else if (isDateError.authorityDate) {
                err = true;
            } else if(isDateError.authorityDateFuture){
                err = true;
            } else if(hasError3||hasError4){
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    return (
        <Container className='py-0 d-flex row align-items-center' fluid>
            <Row className='row-wrapper'>
                <p className='p-0 title primary'>{`${stepValue}. Změna povolení`}</p>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-2 section-title'>Povolení</p>
                <p className='p-0 mb-4 section-description'>Základní údaje o povolení, jehož změna je požadována</p>
                <form className='p-0'>
                    <Col md={6}>
                        <label className='mb-4 povoleni-label'>
                            Správní orgán, který povolení záměru vydal
                            <SearchWithDataList
                                request={request}
                                authorityType={'permissionAuthority'}
                                authorityObj={request.buildIntention.permissionAuthority}
                                setRequest={setRequest}
                                paramFirst={'title'}
                                paramSecond={'typeTitle'}
                                setSearchRequest={setSearchRequest}
                                searchRequest={searchRequest} // obj
                                listData={permAuthList}
                                setListData={setPermAuthList} // arr
                                dynamicVal={BUILDING} // str
                                isSearchLoading={isSearchLoading}
                                setIsSearchLoading={setIsSearchLoading}
                            />
                        </label>
                    </Col>
                    <Col md={6}>
                        <label className='povoleni-label' style={{ color: (!buildIntention?.permissionRefNumber?.trim() && (error || focused.permissionRefNumber)) ? '#C52A3A' : '' }}>
                            Číslo jednací
                            <input
                                required
                                {...(focused.permissionRefNumber && {focused: 'true'})}
                                style={{ border: error && !buildIntention?.permissionRefNumber?.trim() ? 'solid 1px #C52A3A' : '' }}
                                type='text'
                                defaultValue={buildIntention.permissionRefNumber}
                                onBlur={(e) => {setRequest(state => ({...state, buildIntention: {...state.buildIntention, permissionRefNumber: e.target.value}}));
                                    handleFocused('permissionRefNumber');
                                }}
                                className='id-input2 bg-white p-2 mt-1'
                            />
                        </label>
                    </Col>
                    {(!buildIntention?.permissionRefNumber?.trim() && (error || focused.permissionRefNumber)) && <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění tohoto pole není možné pokračovat.</p>
                    </Col>}
                    <Col md={6}>
                        <label className='mt-4 povoleni-label' style={{ color: (hasError3 || isDateError.authorityDate || isDateError.authorityDateFuture) ? '#C52A3A' : '' }}>
                            Ze dne
                            <input
                                type='date'
                                value={buildIntention?.permissionDated ?? ''}
                                style={{ border: (hasError3 || hasError4 || isDateError.authorityDate) ? 'solid 1px #C52A3A' : '' }}
                                max={maxDate.toISOString().split('T')[0]}
                                onChange={e => setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, permissionDated: e.target.value } }))}
                                onBlur={(e) => {
                                    const validatedDate = dateValidation(e.target.value); 
                                    validatedDate && setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, permissionDated: '' } }));
                                    setIsDateError(state => ({...state, authorityDate: validatedDate}));
                                    handleErrors('authorityDateFuture', !e.target.value.trim() || !validDateBeforeOrEquals(e.target.value, maxDate));
                                    handleFocused('permissionDated');
                                }}
                                className='povoleni-input bg-white p-2 mt-1'
                            />
                        </label>
                    </Col>
                    {hasError3 && !isDateError.authorityDate && <Col xs={{ span: 12}} md={{ span: 6}} className='mt-2'>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole &quot;Ze dne&quot; není možné pokračovat.</p>
                    </Col>}
                    <Col md={6}>
                        {

                            buildIntention?.permissionDated?.trim()
                            &&
                            !validDateBeforeOrEquals(buildIntention?.permissionDated, maxDate)
                            &&
                            <Container className="mt-md-2 ps-3 ps-md-0" style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Žádost nelze odeslat. &quot;Ze dne&quot; nesmí být v budoucnosti. Prosím opravte pole &quot;Ze dne&quot;.
                            </Container>
                        }
                    </Col>
                    {isDateError.authorityDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                    </Container>}
                    <Col>
                        <Row>
                            <Col className='mt-4 mb-md-0' sm={12} md={6}>
                                <label className='povoleni-label'>
                                    ID ověřené dokumentace k povolenému záměru
                                    <input 
                                        type='text'
                                        required
                                        {...(focused.verifiedDocumentationId && {focused: 'true'})}
                                        defaultValue={buildIntention.verifiedDocumentationId}
                                        onBlur={(e) => {setRequest(state => ({...state, buildIntention: {...state.buildIntention, verifiedDocumentationId: e.target.value}}));
                                        }} 
                                        className='id-input2 bg-white p-2 mt-1'
                                    />
                                </label>
                            </Col>
                            <Col className='d-flex align-items-end ps-md-3'>
                                <p className='povoleni-additional_text'>vložené do evidence elektronických dokumentací (podle vyhlášky o dokumentaci staveb)</p>
                            </Col>
                        </Row>
                    </Col>
                </form>
            </Row>

            <Row>
                <p className='p-0 mb-2 section-title'>Popis změn</p>
                <label className='p-0 textarea-input' style={{ color: (!request?.form?.changeDescription?.trim() && (error || focused.changeDescription)) ? '#C52A3A' : '' }}>
                    Popis změny a její porovnání s povolením (např. změna platnosti povolení, změna lhůty pro dokončení záměru, změna podmínek rozhodnutí, které nejsou v rozporu s ověřenou dokumentací k povolenému záměru apod.)
                    <Row className='position-relative textarea-with-count-container'>
                        <textarea
                            className='textarea-with-count mt-1 p-2 text-area--validation'
                            onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}
                            style={{ border: error && !request?.form?.changeDescription?.trim() ? 'solid 1px #C52A3A' : '' }}
                            rows="5"
                            required
                            {...(focused.changeDescription && {focused: 'true'})}
                            defaultValue={request.form.changeDescription}
                            onBlur={(e) => {setRequest(state => ({...state, form: {...state.form, changeDescription: e.target.value}}));
                                handleFocused('changeDescription');
                            }}
                        />
                        <CharCount 
                            value={request.form.changeDescription} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                        />
                    </Row>
                </label>
            </Row>
        </Container>
    );
};

ZmenaPovoleniComponent05.propTypes = {
    urlPath: PropTypes.string.isRequired,
};

export default ZmenaPovoleniComponent05;
