import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import { shouldNotSave } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import usePrevious from '../CustomHooks/usePrevious';
import useFirstRender from '../CustomHooks/useFirstRender';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent08 = ({setCheckbox, urlPath}) => {
    const {intention, stepValue, saveFormRef, id} = useContext(FormContext); 
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            nuclearStructure: true,
            ministryOfDefence: false,
            ministryOfInterior: false,
            ministryOfJustice: false,
            applicationExceptionRequirementBuild: null,
        }
    });
    const prevRequest= usePrevious(request);
    const firstRender = useFirstRender();

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }

        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const loadedData = response.data.buildApplicationForms[0];

                if (loadedData) {
                    setRequest(state => ({ ...state, form: {...state.form,
                        nuclearStructure: loadedData.nuclearStructure ?? true,
                        ministryOfDefence: loadedData.ministryOfDefence ?? false,
                        ministryOfInterior: loadedData.ministryOfInterior ?? false,
                        ministryOfJustice: loadedData.ministryOfJustice ?? false,
                        applicationExceptionRequirementBuild: loadedData.applicationExceptionRequirementBuild ?? false,
                    } }));
                }

            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    useEffect(() => {
        if (request.form?.applicationExceptionRequirementBuild === null) {
            return;
        }

        setCheckbox({
            first: request.form.applicationExceptionRequirementBuild ?? false,
        });
    }, [request.form.applicationExceptionRequirementBuild]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            <Row className='row-wrapper'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title secondary'>Žádám o rámcové povolení</p>
                <form className='p-0 form'>
                    <label className='ls-1p25 p-0 d-flex align-items-start row-wrapper'>
                        <input
                            className='me-3'
                            type="radio"
                            name='zadost'
                            value='nuclear'
                            checked={request.form.nuclearStructure}
                            onChange={() => {
                                setRequest(state => ({...state, form: {...state.form, nuclearStructure: true, ministryOfDefence: false, ministryOfInterior: false, ministryOfJustice: false }}));
                            }}
                        />
                            staveb jaderného zařízení a staveb souvisejících, nacházejících se uvnitř i vně areálu jaderného zařízení
                    </label>
                    <p className='p-0 mb-4 title third'>Záměru, u kterého je následně jeho povolení v působnosti jiného stavebního úřadu:</p>
                    <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                        <input
                            className='me-3'
                            type="radio" 
                            name='zadost'
                            value='defense' 
                            checked={request.form.ministryOfDefence} 
                            onChange={() => {
                                setRequest(state => ({...state, form: {...state.form, nuclearStructure: false, ministryOfDefence: true, ministryOfInterior: false, ministryOfJustice: false, applicationExceptionRequirementBuild: false }}));
                            }}
                        />
                            Ministerstvo obrany
                    </label>
                    <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                        <input
                            className='me-3'
                            type="radio" 
                            name='zadost'
                            value='interior' 
                            checked={request.form.ministryOfInterior} 
                            onChange={() => {
                                setRequest(state => ({...state, form: {...state.form, nuclearStructure: false, ministryOfDefence: false, ministryOfInterior: true, ministryOfJustice: false, applicationExceptionRequirementBuild: false }}));       
                            }}
                        />
                            Ministerstvo vnitra
                    </label>
                    <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                        <input
                            className='me-3'
                            type="radio" 
                            name='zadost'
                            value='justice' 
                            checked={request.form.ministryOfJustice} 
                            onChange={() => {
                                setRequest(state => ({...state, form: {...state.form, nuclearStructure: false, ministryOfDefence: false, ministryOfInterior: false, ministryOfJustice: true, applicationExceptionRequirementBuild: false }}));                                  
                            }}
                        />
                            Ministerstvo spravedlnosti
                    </label>
                </form>
            </Row>

            {request.form.nuclearStructure && <Row className=''>
                <p className='p-0 mb-4 title third'>Součástí žádosti je</p>
                <form className='d-flex p-0'>
                    <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.applicationExceptionRequirementBuild ?? false}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, applicationExceptionRequirementBuild: e.target.checked }}));
                            }}
                        />
                            žádost o výjimku z požadavků na výstavbu
                    </label>
                    <span className='ps-md-4 fst-italic rozsah-help-text'>Žádost o výjimku z požadavků na výstavbu se neuplatní v případě záměru v působnosti jiného stavebního úřadu.</span>
                </form>
            </Row>}
            
        </Container>
    );
};

RozsahComponent08.propTypes = {
    
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default RozsahComponent08;