import React, { Fragment, useContext, useEffect, useState } from 'react';
import '../../css/reusable.css';
import { counter, saved } from '../../constants/zadostState';
import { Container, Row, Col } from 'react-bootstrap';
import { closeErrorPanel, iconAutoSave, importError } from '../../assets';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from  'prop-types';
import FormContext from '../../formContexts/FormContext';
import { getTextfromSectionName } from '../../helperFunctions/helpers';
import { ProgressIndicator } from '../index';

const FormHeader = ({ header, isMobileView, averageProgress, currentlyUploading }) => {
    const [, forceUpdate] = useState(false);
    const saving = saved.value;
    const { uploadError, fetch, setFetch } = useContext(FormContext);
    const [lastSaveTime, setLastSaveTime] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const errorsToShow = [...new Map([...uploadError]
        .map(element => [element.where, element])).values()]
        .filter((el) => getTextfromSectionName(el.where));
    const shouldShowErrorPanel = errorsToShow?.length > 0;
    const [panelOpen, setPanelOpen] = useState(false);
    const isSizeExceeded = [...errorsToShow].find(el => el.why === 'total_size') ?? false;
    const isTotalAmountExceeded = [...errorsToShow].find(el => el.why === 'total_amount') ?? false;
    const apiCallError = [...errorsToShow].find(el => el.why === 'api_call') ?? false;
    const location = useLocation();
    const hasWrongFormat = [...errorsToShow].some(el => el.why === 'format');
    const cooldownPeriod = 3000;
    
    const fetchZamerAgain = () => {
        const currentTime = Date.now();
        if (currentTime - lastSaveTime > cooldownPeriod) {
            setLastSaveTime(currentTime);
            setFetch(!fetch);
            setIsButtonDisabled(true); 
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, cooldownPeriod);
        } else {
            console.log('Please, wait before fetching again.');
        }
    };

    useEffect(() => {
        const unsubscribe = saved.subscribe(() => {
            forceUpdate(update => !update);
        });
  
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (!shouldShowErrorPanel) {
            return;
        }

        if (!panelOpen && shouldShowErrorPanel) {
            setPanelOpen(shouldShowErrorPanel);
        }

    }, [shouldShowErrorPanel, errorsToShow.length]);

    return(
        <Container fluid className={`p-3 p-md-5 d-flex align-items-center justify-content-center ${panelOpen ? 'mt-lg-5' : ''}`}>
            {(panelOpen && errorsToShow.length > 0) && (
                <Container className='import_error_container_bg' style={{backgroundColor: apiCallError ? '#F9C13C' : ''}}>
                    <Container className='import_error_container header d-flex align-items-center justify-content-between px-4 py-3 mb-1' style={{backgroundColor: apiCallError ? '#F9C13C' : ''}}>
                        <Container className='d-flex align-items-center'>
                            <img src={importError} alt='upozorneni'/>
                            <div className='import_error_text ps-3'>
                                {(errorsToShow.length > 1 && !apiCallError) ? 'Chyba nahrávání souboru v sekcich ' : errorsToShow.length === 1 && !apiCallError ? 'Chyba nahrávání souboru v sekci ' : ''}
                                {errorsToShow.map((el, idx, arr) => {
                                    return (<Fragment key={el?.uid || idx}>
                                        {
                                            !apiCallError
                                            &&
                                            <>
                                                &quot;{getTextfromSectionName(el.where, true) || ''}&quot;{(idx < arr.length - 1 || el.why === 'format') ? ', ' : ''}
                                            </>
                                        }
                                        {el.why === 'format' && 'Nepodporovaný formát souboru, nelze vložit'}
                                        {el.why === 'api_call' && <p>Nepodařilo se předvyplnit informace o záměru. Můžete <span onClick={fetchZamerAgain} style={{color: isButtonDisabled ? '#aaaaaa' : '#3077B7', cursor: isButtonDisabled ? 'not-allowed' : 'pointer', font: 'inherit'}} className='d-inline-block text-decoration-underline cursor-pointer'>opakovat požadavek na vyplnění</span> nebo formulář vyplnit ručně.</p>}
                                    </Fragment>);
                                }
                                )}
                                {!apiCallError && (isSizeExceeded ? 
                                    ' - Soubory nemohou být nahrány, překročen limit velikosti dokumentace 5 GB.'
                                    : isTotalAmountExceeded ? ' - Soubory nemohou být nahrány, překročen limit 50 000 souborů dokumentace.'
                                        : '')}
                                {!apiCallError && (isSizeExceeded ?
                                    ' Prosím optimalizujte soubory pro nižší velikost a zkuste znovu.' 
                                    : isTotalAmountExceeded ?
                                        ' Prosím slučte soubory do větších celků a zkuste znovu. ' 
                                        : !hasWrongFormat ? 'Prosím zkontrolujte své připojení a zkuste soubor nahrát znovu.' 
                                            : '')}
                            </div>
                        </Container>
                        <img src={closeErrorPanel} alt='zavrit' onClick={() => {setPanelOpen(false);}} style={{cursor: 'pointer'}} />
                    </Container>
                </Container>)}
            <Row className='base-width d-flex justify-content-between align-items-center' style={!isMobileView ? { borderBottom: 'solid #ddd 1px', paddingBottom: '5rem'} : { borderBottom: 'none', paddingBottom: '0'}}>
                <Col lg={7} className='my-3 my-md-0'>
                    <Row className='d-flex flex-column'>
                        <Col>
                            <p style={{fontSize: '2rem', fontWeight: '500', lineHeight: '3rem', letterSpacing: '0.01rem', marginBottom: '1rem'}}>{header.title}</p>
                        </Col>
                        {!isMobileView && <Col>
                            <p style={{fontSize: '1rem', fontWeight: '400', lineHeight: '1.5rem', letterSpacing: '0.0125rem'}}>
                                {header.perex}
                            </p>
                            {header?.perex1 && <p className='mt-2' style={{fontSize: '1rem', fontWeight: '400', lineHeight: '1.5rem', letterSpacing: '0.0125rem'}}>
                                {header.perex1}
                            </p>}
                        </Col>}
                    </Row>
                </Col>
                <Col md={{span: 4, offset: 1}} className='d-none d-lg-block' >
                    <Container style={{minHeight: '158px', maxWidth: '420px', padding: '1.5rem', borderRadius: '3px', border: 'solid 1px #DDD', backgroundColor: '#FFF'}}>
                        <Row className='d-flex flex-column'>
                            {currentlyUploading?.length > 0 && 
                            <ProgressIndicator 
                                type={'header'}
                                averageProgress={averageProgress}
                            />}
                            {(!currentlyUploading || currentlyUploading?.length === 0) && (!saving
                                ?
                                <span style={{marginBottom: '3.80rem'}}>Ukládám formulář...</span>
                                :
                                <>
                                    <Col className='d-flex' style={{marginBottom: '1rem'}}>
                                        <img src={iconAutoSave} style={{marginRight: '1rem'}}/>
                                        <p style={{fontSize: '1rem', fontWeight: '700', lineHeight: '1.5rem', letterSpacing: '0.015rem'}}>Automaticky uloženo</p>
                                    </Col>
                                    <Col>
                                        <p style={{marginLeft: '2.25rem', marginBottom: '1rem', fontSize: '0.938rem', fontWeight: '500', lineHeight: '1.464rem', letterSpacing: '0.018rem'}}>před {counter} sekundami</p>
                                    </Col>
                                </>)}
                            <Col>
                                <Link 
                                    to='/zadosti'
                                    state={{from: location}}
                                >
                                    <button
                                        className='w-100 d-flex align-items-center justify-content-center button-solid'
                                        style={{fontSize: '0.813rem',
                                            fontWeight: '700',
                                            lineHeight: '1.5rem',
                                            letterSpacing: '0.02rem',
                                            height: '39px',
                                            border: 'solid #2362A2',
                                            padding: '8px 12px',
                                            borderRadius: '3px',
                                            backgroundColor: '#2362A2',
                                            color: '#FFF'}}
                                    >
                                        {!saving ? 'Automaticky ukládám' : 'Uložit a zavřít'}
                                    </button>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

FormHeader.propTypes = {
    header: PropTypes.objectOf(String),
    isMobileView: PropTypes.bool,
    currentlyUploading: PropTypes.arrayOf(Object),
    averageProgress: PropTypes.number,
};

export default FormHeader;