
import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AuthContext } from 'react-oauth2-code-pkce';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { uploadedFile } from '../../assets';
import { PATHS } from '../../constants/sharedConstants';
import { ZadostiDetailPodani } from '../index.js';
import { makeCopyRequest } from '../../helperFunctions/helpers';
import { file, fileIcon } from '../../assets';
import '../../css/zadosti.css';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config.js';
import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';

const ZadostiDetailMainSection = ({zadostiDetail}) => {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ dropDownlVisible, setDropDownVisible ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ focused, setFocused ] = useState(false);
    const attachments = zadostiDetail?.detail?.attachments || [];
    const APPLICATION_PID = zadostiDetail?.detail?.applicationPid;
    const BPP_UID = zadostiDetail?.detail?.bppFile?.uid;
    const BPP_FILENAME = zadostiDetail?.detail?.bppFile?.fileName;
    const PDF_UID = zadostiDetail?.detail?.pdfFile?.uid;
    const PDF_FILENAME = zadostiDetail?.detail?.pdfFile?.fileName;
    const attachmentsToMap = attachments.filter(att => (!(att.fileName.startsWith('build-application')) && !(att.fileName.startsWith('build-intention'))));
    const attachmentToPreviewLength = attachmentsToMap.length;

    const showModal = () => {
        if (attachmentToPreviewLength)
        {
            setDropDownVisible(!dropDownlVisible);
            setFocused(!focused);
        }
        else return;
    };

    const downloadPdf = async (uid, isDocumentation = false, fileName = null) => {
        if (!uid) {
            return;
        }
        try {
            const source = axios.CancelToken.source();

            const response = await axios.get(
                urlJoin(apiConfig.getAndQueryBaseUrl, `requests/${APPLICATION_PID}/attachment/${uid}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                    params: {
                        'fileName': fileName ?? (isDocumentation ? BPP_FILENAME : PDF_FILENAME),
                    },
                });

            if (isRequestOK(response?.status)) {
                if(isDocumentation)
                {
                    const blob = new Blob([response.data], { type: 'application/zip' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'dokumentace.zip';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                }
                else
                {
                    window.open(URL.createObjectURL(response.data));
                }
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
        }
    };
    
    const handleClick = async (id) => {
        setIsLoading(true);
        try {
            const { path, applicationId } = await makeCopyRequest(id, token, PATHS);

            if(!path || !applicationId) {
                return null;
            }

            return navigate(`/zadost/${path.target}/${applicationId}`);
        } catch (e) {
            // TODO error handling
            setIsLoading(false);
            console.log(e);
        }
    };

    return (
        <Container className='p-3 pb-5 mb-4 p-md-0 d-flex flex-column align-items-center' fluid>
            <Row className='base-width'>
                <Col lg={4} className='d-flex flex-column align-items-xl-start mb-3 mb-xl-0 zadosti-detail-info'>
                    <p className='d-flex p-0 mb-3 rizeni-detail-title'>Podání</p>
                    <ZadostiDetailPodani item={zadostiDetail.detail}/>
                    <button type='button' className='p-1 zadosti-detail-button id-input2 mb-3' onClick={() => downloadPdf(PDF_UID, false, null)}><img src={file} alt='file' className='pe-2'/>Stáhnout žádost v PDF</button>

                    <button type='button' className='p-1 zadosti-detail-button id-input2 mb-3' onClick={() => downloadPdf(BPP_UID, true, null)}><img src={file} alt='file' className='pe-2' />Stáhnout dokumentaci</button>

                    <button className='attachment--dropdown-container mb-3 zadosti-detail-button--dropdown id-input2 dropdown-input-zadosti' {...(focused ? {focused: 'true'} : {focused: 'false'})} style={{height: dropDownlVisible && 'auto'}} onBlur={() => showModal()} onClick={() => showModal()}>
                        <p className='dropdown-placeholder'>
                            {attachmentToPreviewLength ? 'Stáhnout přílohy' : 'Přílohy nejsou k dispozici'}
                        </p>
                        {
                            dropDownlVisible
                            &&
                            <>
                                {
                                    attachmentToPreviewLength &&
                                        <>
                                            {
                                                attachmentsToMap.map((item, index) => {
                                                    return (
                                                        <div key={index} className='d-flex align-items-center dropdown--items-container' onClick={() => downloadPdf(item.uid, false, item.fileName)}>
                                                            <img src={uploadedFile} alt={item.fileName} />
                                                            <p>{item.fileName}</p>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </>
                                }
                            </>
                        }
                    </button>
                    {/* <button type='button' className='p-1 zadosti-detail-button'><img src={file} alt='file' className='pe-2' />Stáhnout potvrzení</button> */}
                    <button
                        type='button'
                        className='p-1 zadosti-detail-button id-input2 mb-3'
                        onClick={() => handleClick(zadostiDetail?.detail?.applicationPid)}
                    >
                        <img src={fileIcon} alt='file' className='pe-2' />
                        {isLoading ? 'Vytvářím kopii...' : 'Vytvořit kopii'}
                    </button>
                    {/* </Row> */}
                </Col>
                {/* <Col lg={8} className='d-flex flex-column align-items-xl-start w-99 ps-lg-4'>
                    <p className='d-flex p-0 mb-3 rizeni-detail-title'><img src={alarm} alt='alarm' className='pe-2' />Historie</p>
                    <ZadostiDetailHistory history={zadostiDetail?.detail?.history} />
                </Col> */}
            </Row>
        </Container>
    );
};

ZadostiDetailMainSection.propTypes = {
    zadostiDetail: PropTypes.shape({
        detail: PropTypes.shape({
            applicationPid: PropTypes.string,
            pid: PropTypes.string,
            projectName: PropTypes.string,
            projectId: PropTypes.string,
            processId: PropTypes.string,
            state: PropTypes.string,
            date: PropTypes.string,
            mode: PropTypes.string,
            history: PropTypes.array,
            attachments: PropTypes.array,
            bppFile: PropTypes.object,
            pdfFile: PropTypes.object
        })
    }),
};

export default ZadostiDetailMainSection;
