import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import { errorIcon } from '../../assets/index.js';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { PozemkyTable, StavbyTable, FormInput, ZamerSelectionControls, VodniDilo } from '../../components/index.js';
import { BUILDING_ADDRESS } from '../../constants/form15.js';
import FormContext from '../../formContexts/FormContext.js';
import { shouldNotSave, spreadParcelsAndConstructions, spreadObject, hasZamerError, constructionOrParcelToRemoveNotAdded, setUploadedDocumentsSizeFromZamer, useOnlyRequiredKeys } from '../../helperFunctions/helpers.js';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    newBuilding: false,
    buildingTypePurpose: '',
    changeOfFinishedBuilding: false,
    reasonChangeOfBuildingUse: false,
    newBuildingUse: '',
    buildingTemporary: false,
    setOfBuildings: false,
    techOrMarketFacility: false,
    siteFacility: false,
    maintenanceAndModificationPermission: false,
    waterworks: false,
    buildingTemporaryDuration: '',
    parcelChangeSuggestion: '',
    waterworksPurpose: '',
    watercourseName: '',
    watercourseId: '',
    hydrogeologicalAreaName: '',
    hydrogeologicalAreaID: '',
    documentationId: '',
    useNeighborFacilityReason: '',
};

const ZamerComponent15 = ({ progressElements, setProgressElements, urlPath }) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id, setUploadedBytes} = useContext(FormContext); 
    const [ focused, setFocused ] = React.useState({
        nazev: false,
        nazev2: false,
        date: false,
        ucelVodnihoDila: false,
        nazevVodnihoDila: false,
        idVodnihoDila: false,
    });

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
            removeBuilding: false,
            removeFacility: false,
            removeTerrain: false,
        },
        buildIntention: buildIntentionModel
    });
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);

    const buildIntention = request?.buildIntention;
    const form = request?.form;

    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        tmpBuildingDate: false,
    });
    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        buildConstructions: [],
        buildParcels: [],
    });
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);
    
                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj
                    );
                    const intention = response.data?.buildIntention;
                    const formData = response.data?.buildApplicationForms?.[0];
                    const formStructure = request.form;
                    const filteredDataToSave = useOnlyRequiredKeys(formStructure, formData);
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest), ...((formData && formData.id) ? { form: { ...state.form, ...filteredDataToSave } } : {}) }));

                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value,
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    const documentations = response.data?.buildApplication?.documentations ?? null;
                    if (documentations && !progressElements?.length) setUploadedDocumentsSizeFromZamer(documentations, setUploadedBytes);
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (
            (buildIntention?.buildParcels) ||
            (buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(buildIntention?.buildParcels ?? [])],
                buildConstructions: [...(buildIntention?.buildConstructions ?? [])],
            }));
            setRequest(prev => ({...prev, buildConstructions: buildIntention?.buildConstructions, buildParcels: buildIntention?.buildParcels}));
        }

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest, request)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention, form]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            } else if (!form?.removeBuilding && !form?.removeFacility && !form?.removeTerrain) {
                err = true;
            } else if (constructionOrParcelToRemoveNotAdded(form, parcelAndConstructionData ,'construction')) {
                err = true;
            } else if (constructionOrParcelToRemoveNotAdded(form, parcelAndConstructionData ,'parcel')) {
                err = true;
            } else if ((buildIntention?.buildingTemporary && !buildIntention?.buildingTemporaryDuration?.trim()) || isDateError.tmpBuildingDate) {
                err = true;
            }
            else if (buildIntention?.waterworks && !buildIntention?.waterworksPurpose)
            {
                err = true;
            }

            if (buildIntention?.address?.zip) {
                const cleanedValue = buildIntention?.address?.zip.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));


    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                    />

                    {/* Místo záměru */}
                    <Row className='row-wrapper border rounded p-4'>
                        <p className='section-title p-0 mb-4'>Místo (nepovinné)</p>
                        <form className='p-0'>

                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 title secondary'>Žádám o povolení odstranění:</p>
                        <form className='p-0'>
                            <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={form.removeBuilding ?? false}
                                    onChange={(e) => {
                                        setRequest(state => ({...state, form: {...state.form, removeBuilding: e.target.checked }}));
                                    }}
                                />
                                    stavby              
                            </label>                  
                            <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={form.removeFacility ?? false}
                                    onChange={(e) => {
                                        setRequest(state => ({...state, form: {...state.form, removeFacility: e.target.checked }}));
                                    }}
                                />
                                    zařízení
                            </label>
                            <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                                <input
                                    className='me-3'
                                    type="checkbox"
                                    checked={form.removeTerrain ?? false}
                                    onChange={(e) => {
                                        setRequest(state => ({...state, form: {...state.form, removeTerrain: e.target.checked }}));
                                    }}
                                />
                                    terénních úprav
                            </label>
                            { error && !form?.removeBuilding && !form?.removeFacility && !form?.removeTerrain &&
                            <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vybrání alespoň jednoho pole není možné pokračovat.
                            </Container>}
                        </form>
                    </Row>
                    {/* Charakteristika záměru */}
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 section-title'>Základní údaje o odstraňované stavbě, zařízení nebo terénních úpravách</p>
                        <form className='d-flex flex-column p-0'>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.buildingTemporary)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporary: e.target.checked } })); }} />
                                Stavba dočasná
                            </label>
                            {buildIntention.buildingTemporary && <Row className='mt-3 row-wrapper'>
                                <Col md={6}>
                                    <label className='label p-0' style={{ color: (!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.date)) || isDateError.tmpBuildingDate ? '#C52A3A' : 'black' }}>
                                    Doba trvání dočasné stavby do
                                        <input
                                            required
                                            type='date'
                                            style={{ border: ((error && !buildIntention?.buildingTemporaryDuration?.trim()) || isDateError.tmpBuildingDate) && 'solid 1px #C52A3A' }}
                                            className='id-input2 mb-2'
                                            value={buildIntention?.buildingTemporaryDuration ?? ''}
                                            {...(focused.date && { focused: 'true' })}
                                            onChange={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, buildingTemporaryDuration: e.target.value } })); }}
                                            onBlur={(e) => {
                                                const validatedDate = dateValidation(e.target.value); 
                                                validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, buildingTemporaryDuration: '' } }));
                                                setIsDateError(state => ({...state, tmpBuildingDate: validatedDate}));
                                                handleFocused('date');
                                            }}
                                        />
                                        {isDateError.tmpBuildingDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                                        </Container>}
                                        {(!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.date)) && !isDateError.tmpBuildingDate &&
                                            <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                                Bez vyplnění pole &quot;Doba trvání dočasné stavby do&quot; není možné pokračovat.
                                            </Container>}
                                    </label>
                                </Col>
                            </Row>}

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.waterworks)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworks: e.target.checked } })); }} />
                                Vodní dílo
                            </label>
                            {buildIntention.waterworks &&
                            <VodniDilo
                                buildIntention={buildIntention}
                                focused={focused}
                                request={request}
                                setRequest={setRequest}
                                handleFocused={handleFocused}
                            />}
                        </form>
                    </Row>
                    
                    {
                        (form.removeBuilding || form.removeFacility)
                        &&
                        <>
                            <StavbyTable
                                title={'Stavby'}
                                subtitle={'Údaje o odstraňované stavbě'}
                                progressElements={progressElements}
                                setProgressElements={setProgressElements}
                                urlPath={urlPath}
                                dataToAdd={parcelAndConstructionData.buildConstructions}
                                setDataToAdd={setParcelAndConstructionData}
                            />
                            {constructionOrParcelToRemoveNotAdded(form, parcelAndConstructionData, 'construction') &&
                            <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyhledání stavby, nebo zařízení není možné pokračovat.
                            </Container>}
                        </>
                    }
                    
                    {
                        form.removeTerrain &&
                        <>
                            <PozemkyTable
                                subtitle={'na kterých se nachází odstraňovaná terenní úprava'}
                                progressElements={progressElements}
                                setProgressElements={setProgressElements}
                                urlPath={urlPath}
                                dataToAdd={parcelAndConstructionData.buildParcels}
                                setDataToAdd={setParcelAndConstructionData}
                            />
                            {constructionOrParcelToRemoveNotAdded(form, parcelAndConstructionData, 'parcel') &&
                            <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyhledání pozemku není možné pokračovat.
                            </Container>}
                        </>
                    }
                </Container>}
        </>
    );
};

ZamerComponent15.propTypes = {
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent15;
