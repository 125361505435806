import React, { useState, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { GovButton, GovTabs, GovTabsItem } from '@gov-design-system-ce/react';
import { Container, Row, Col } from 'react-bootstrap';
import { ZadostiTableDisplay, ZadostiBlockDisplayDeveloped, ZadostiBlockDisplay, Filter, RecordNotFound, PaginateResults, NejcastejsiZadosti } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { iconExport } from '../../assets';
import { deleteZadosti, fetchZadosti, fetchDevelopedZadosti, isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import '../../css/zadostiMainSection.css';
import { appendNumberToGovLabel, getFilterBodyBySections, setFiltersForZadosti, updateNumberOnGovLabel } from '../../helperFunctions/helpers.js';
import { useLocation } from 'react-router-dom';
import { ZADOSTI } from '../../constants/sharedConstants.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { isRequestSentOrError } from './ZadostiBlockDisplayDeveloped.jsx';
const ODESLANE_HEADERS = ['PID', 'Project ID', 'Project Name', 'Request Date', 'Request Type', 'Status'];
const ROZPRACOVANE_HEADERS = ['id', 'title', 'created', 'updated', 'intentionNumber', 'intentionName', 'attachments', 'documentations'];

const ZadostiMainSection = ({ isActive, setIsActive }) => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    const [list, setList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [listDeveloped, setListDeveloped] = useState([]);
    const [developedZadostiFetching, setDevelopedZadostiFetching] = useState(true);
    const resultPerPage = 24;
    const { token } = useContext(AuthContext);
    const [startIndexOdeslane, setStartIndexOdeslane] = useState(0);
    const [startIndexRozpracovane, setStartIndexRozpracovane] = useState(0);
    const endIndexOdeslane = startIndexOdeslane + resultPerPage;
    const endIndexRozpracovane = startIndexRozpracovane + resultPerPage;
    const page = 0;
    // TODO determine what to do with size
    const size = 500;
    const [postBody, setPostBody] = useState(getFilterBodyBySections(ZADOSTI));
    const location = useLocation();
    const comingFrom = location.state?.from?.pathname;
    const isComingFromForm = comingFrom?.startsWith('/zadost');
    const btnToClick = document.querySelectorAll('.gov-tabs__item')[1]?.firstChild;
    const isReset = useMemo(() => JSON.stringify(postBody) === JSON.stringify(getFilterBodyBySections(ZADOSTI)), [postBody]);
    const firstRender = useFirstRender();

    useEffect(() => {
        if (isComingFromForm && btnToClick) {
            btnToClick.click();
        }
    }, [isComingFromForm, btnToClick]);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            }

            setIsMobileView(false);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        setFiltersForZadosti(
            firstRender,
            isReset,
            setStartIndexOdeslane,
            list,
            postBody,
            setFilteredList
        );
    }, [postBody, isReset]);

    const getData = async () => {
        const source = axios.CancelToken.source();

        try {
            const [responseRequests, responseDevelopedRequests] = await Promise.all(
                [fetchZadosti(postBody, token, source), fetchDevelopedZadosti(page, size, token, source)]
            );
            if (isRequestOK(responseRequests.status) && isRequestOK(responseDevelopedRequests.status)) {
                const sentRequests = responseDevelopedRequests?.data?.filter(item => isRequestSentOrError(item));
                const sortedList = [...(responseRequests?.data?.data || []).sort((a, b) => a.requestDate < b.requestDate ? 1 : -1)];
                const list = [...sentRequests, ...sortedList];
                setList(list);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFetching(false);
        }

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (!listDeveloped.length) {
            (async () => {

                try {
                    const response = await fetchDevelopedZadosti(page, size, token, source);
                    if (isRequestOK(response.status)) {
                        const listDeveloped = [...response.data].filter(item => !isRequestSentOrError(item));

                        setListDeveloped(listDeveloped);
                        appendNumberToGovLabel(listDeveloped?.length ?? null);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setDevelopedZadostiFetching(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
    }, []);

    useEffect(() => {
        updateNumberOnGovLabel(listDeveloped.length);
    }, [listDeveloped.length]);

    const exportToCSV = (headers, csvData) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.csv';
        const ws = XLSX.utils.json_to_sheet(csvData);

        /* HEADERS */
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'list' + fileExtension);
    };

    const handleDelete = (id) => {
        const source = axios.CancelToken.source();

        (async () => {
            const response = await deleteZadosti(id, token, source);
            // No needs to nullify the array and refetch the whole list
            if ((200 <= response?.status) && (response?.status < 300)) {
                setListDeveloped(prev => [...prev].filter(el => el.id !== response.data));
            } else {
                console.log(response);
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    };

    return (
        <Container fluid>
            <Container className='base-width my-0 mx-auto px-2 py-4 px-md-5 px-xl-0' fluid>
                <GovTabs className='remove-border' type="text" wcag-label="Basic information about the gov.cz design system">
                    <GovTabsItem label='Odeslané'>
                        <Container fluid>
                            <Row>
                                <Filter
                                    isWithFolding={false}
                                    setPostBody={setPostBody}
                                    postBody={postBody} 
                                    isActive={isActive}
                                    setIsActive={setIsActive}                       
                                    page={ZADOSTI} 
                                />
                            </Row>
                            {fetching ? (
                                <Container fluid className='px-2 py-4 px-md-5 px-xl-0'>
                                    <span>Načítám žádosti...</span>
                                </Container>
                            ) : (!fetching && (!list.length ? (
                                <RecordNotFound />
                            ) : (isActive === 'block' ? (<>
                                <Row className='d-flex'>
                                    {(isReset || firstRender) ? ( 
                                        list?.map((item, index, array) => {
                                            if (array.length <= resultPerPage) {
                                                return (
                                                    <Col key={item.pid || item?.id} xs={12} md={6} lg={4} className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'>
                                                        {isRequestSentOrError(item) ? (
                                                            <ZadostiBlockDisplayDeveloped
                                                                item={item}
                                                                key={item.id}
                                                                handleDelete={handleDelete}
                                                            />
                                                        ) : (
                                                            <ZadostiBlockDisplay
                                                                item={item}
                                                                key={item.pid}
                                                            />
                                                        )}
                                                    </Col>
                                                );
                                            } else if (index >= startIndexOdeslane && index < endIndexOdeslane) {
                                                return (
                                                    <Col key={item.pid || item?.id} xs={12} md={6} lg={4} className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'>
                                                        {isRequestSentOrError(item) ? (
                                                            <ZadostiBlockDisplayDeveloped
                                                                item={item}
                                                                key={item.id}
                                                                handleDelete={handleDelete}
                                                            />
                                                        ) : (
                                                            <ZadostiBlockDisplay
                                                                item={item}
                                                                key={item.pid}
                                                            />
                                                        )}
                                                    </Col>
                                                );
                                            }
                                        })) : (
                                        !filteredList.length ? (
                                            <RecordNotFound /> 
                                        ) : (filteredList?.map((item, index, array) => {
                                            if (array.length <= resultPerPage) {
                                                return (
                                                    <Col key={item.pid} xs={12} md={6} lg={4} className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'>
                                                        {<ZadostiBlockDisplay item={item} key={item.pid} />}
                                                    </Col>
                                                );
                                            } else if (index >= startIndexOdeslane && index < endIndexOdeslane) {
                                                return (
                                                    <Col key={item.pid} xs={12} md={6} lg={4} className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'>
                                                        {<ZadostiBlockDisplay item={item} key={item.pid} />}
                                                    </Col>
                                                );
                                            } 
                                        })))}
                                </Row>
                                {(((isReset || firstRender) && list.length > resultPerPage) || (!isReset && !firstRender && filteredList.length > resultPerPage)) && 
                                <Row className='d-flex w-100 align-items-center justify-content-center pt-3'>
                                    <PaginateResults
                                        resultPerPage={resultPerPage}
                                        listData={(isReset || firstRender) ? list : filteredList}
                                        isMobileView={isMobileView}
                                        startIndex={startIndexOdeslane}
                                        setStartIndex={setStartIndexOdeslane}
                                    />
                                </Row>}
                                <Row className='pt-3 ms-md-2 ms-lg-1 ms-xl-2'>
                                    <GovButton className='p-0' type='outlined' variant={'primary'} size='s' onGov-click={() => exportToCSV(ODESLANE_HEADERS, list)}>
                                        <Container className='d-flex justify-content-center align-items-center' style={{ width: '160px', fontWeight: '500', fontSize: '.909rem', lineHeight: '1.464rem', letterSpacing: '0.018rem' }}                                                >
                                            <img src={iconExport} alt='export icon' style={{ marginRight: '.75rem' }} />
                                                    Exportovat .csv
                                        </Container>
                                    </GovButton>
                                </Row>
                            </>) : (<>
                                <Row className='overflow-x-scroll overflow-x-md-hidden' style={{ border: 'solid #DDDDDD .5px' }}>
                                    <ZadostiTableDisplay items={(isReset || firstRender) ? list : filteredList} setPostBody={setPostBody} postBody={postBody} isReset={isReset}/>
                                </Row>
                                <Row className='pt-3'>
                                    <GovButton className='my-2 p-0' type='outlined' variant={'primary'} size='s' onGov-click={() => exportToCSV(ODESLANE_HEADERS, list)}>
                                        <Container className='d-flex justify-content-center align-items-center' style={{ width: '160px', fontWeight: '500', fontSize: '.909rem', lineHeight: '1.464rem', letterSpacing: '0.018rem' }}                                                >
                                            <img src={iconExport} alt='export icon' style={{ marginRight: '.75rem' }} />
                                                    Exportovat .csv
                                        </Container>
                                    </GovButton>
                                </Row>
                            </>))))}
                        </Container>
                    </GovTabsItem>
                    <GovTabsItem label='Rozpracované'>
                        <Container fluid>
                            <Row>
                                {developedZadostiFetching ? (
                                    <Container fluid className='px-2 py-4 px-md-5 px-xl-0'>
                                        <span>Načítám žádosti...</span>
                                    </Container>
                                ) : (!developedZadostiFetching && (!listDeveloped.length ? (
                                    <RecordNotFound />
                                ) : (<>
                                    <Row className='d-flex'>
                                        {listDeveloped.map((item, index, array) => {
                                            if (array.length <= resultPerPage) {
                                                return (
                                                    <Col key={item.id} xs={12} md={6} xl={4} className='d-flex justify-content-center justify-content-md-start py-2 px-md-2 px-lg-0'>
                                                        <ZadostiBlockDisplayDeveloped item={item} key={item.id} handleDelete={handleDelete} />
                                                    </Col>
                                                );
                                            } else if (index >= startIndexRozpracovane && index < endIndexRozpracovane) {
                                                return (
                                                    <Col key={item.id} xs={12} md={6} xl={4} className='d-flex justify-content-center justify-content-md-start py-2 px-md-2 px-lg-0'>
                                                        <ZadostiBlockDisplayDeveloped item={item} key={item.id} handleDelete={handleDelete} />
                                                    </Col>
                                                );
                                            }
                                        })}
                                        {listDeveloped.length > resultPerPage && 
                                        <Row className='d-flex w-100 align-items-center justify-content-center pt-3'>
                                            <PaginateResults
                                                resultPerPage={resultPerPage}
                                                listData={listDeveloped}
                                                isMobileView={isMobileView}
                                                startIndex={startIndexRozpracovane}
                                                setStartIndex={setStartIndexRozpracovane}
                                            />
                                        </Row>}
                                        <Row className='pt-3'>
                                            <GovButton className='m-0 p-0' type='outlined' variant={'primary'} size='s' onGov-click={() => exportToCSV(ROZPRACOVANE_HEADERS, listDeveloped)}>
                                                <Container className='d-flex justify-content-center align-items-center' style={{ width: '160px' }} >
                                                    <img src={iconExport} alt='export icon' style={{ marginRight: '.75rem' }} />
                                                        Exportovat .csv
                                                </Container>
                                            </GovButton>
                                        </Row>
                                    </Row>
                                </>)))}
                            </Row>
                        </Container>
                    </GovTabsItem>
                </GovTabs>
            </Container>
            <Container className='mt-4 py-1 bg-white w-100' fluid>
                <NejcastejsiZadosti />
            </Container>
        </Container>
    );
};
ZadostiMainSection.propTypes = {
    isActive: PropTypes.string.isRequired,
    setIsActive: PropTypes.func.isRequired,
};

export default ZadostiMainSection;