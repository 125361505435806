import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import { infoCircle, warningUnfilled } from '../assets';

const CharCount = ({count, limit, setCount, propToUpdate, value}) => {
    
    useEffect(() => {
        if (!value) {
            return;
        }

        if (count === 0 && value?.length > 0) {
            setCount(prev => ({ ...prev, [propToUpdate]: { ...prev[propToUpdate], count: value.length } }));
        }
    }, [value]);

    return (
        <Container className='textarea-counter d-flex flex-column'>
            <Row className='align-self-end'><Row className='bg-white textarea-couner-text'>{`${count}/${limit}`}</Row></Row>
            {(count >= limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning ps-3 my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
            {(limit === 10000 && count >= 1000) && <Row className='mb-2'><p className='d-flex align-items-center p-0 kontrola-info ps-3 my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>}
        </Container>
    );
};

CharCount.propTypes = {
    count: PropTypes.number,
    limit: PropTypes.number,
    setCount: PropTypes.func,
    propToUpdate: PropTypes.number,
    value: PropTypes.string,
};

export default CharCount;