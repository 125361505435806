let items = [
    {
        stage: 1,
        name: 'Záměr',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 2,
        name: 'Stavba',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 3,
        name: 'Zpracovatel dokumentace',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 4,
        name: 'Projektant',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 5,
        name: 'Zhotovitel stavby',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 6,
        name: 'Kontrola a podání',
        error: false,
        warning: false,
        seen: false,  
    },
];

const ADDITION_BUILDING_CONT = [
    {
        id: 1,
        label: 'Dodatek odlišující osobu podnikatele nebo druh podnikání vztahující se k této osobě nebo jí provozovanému druhu podnikání (nepovinné)',
        type: 'text',
        refer: 'addition',
        required: false,
        state: 'appendix',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
];

const ADDITION_PROCESSOR = [
    {
        id: 1,
        label: 'Dodatek odlišující osobu podnikatele nebo druh podnikání vztahující se k této osobě nebo jí provozovanému druhu podnikání (nepovinné)',
        type: 'text',
        refer: 'addition',
        required: false,
        state: 'appendix',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
];

const DOCUMENT_PROCESSOR_BUSINESS =  [
    {
        id: 1,
        label: 'IČO',
        type: 'text',
        refer: 'documentProcessorBusiness-ico',
        info: 'Uveďte identifikační číslo osoby (IČO), bylo-li přiděleno.',
        required: true,
        state: 'ico',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 2,
        label: 'Jméno',
        type: 'text',
        refer: 'documentProcessorBusiness-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'names',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 3,
        label: 'Příjmení',
        type: 'text',
        refer: 'documentProcessorBusiness-surname',
        required: true,
        state: 'lastName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 4,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'documentProcessorBusiness-birthSurname',
        info: 'Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení',
        required: false,
        state: 'lastNameOrigin',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
];

const DOCUMENT_PROCESSOR_PO =  [
    {
        id: 1,
        label: 'IČO',
        info: 'Uveďte identifikační číslo osoby (IČO), bylo-li přiděleno.',
        type: 'text',
        refer: 'documentProcessorPO-ico',
        required: true,
        state: 'ico',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 2,
        label: 'Název nebo obchodní firma',
        type: 'text',
        refer: 'documentProcessorPO-firm',
        required: true,
        state: 'poName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
];

const DOCUMENT_PROCESSOR_LOCATION = [
    {
        id: 1,
        label: 'Obec',
        info: 'Uveďte adresu zapsanou v obchodním rejstříku nebo jiné zákonem upravené evidenci jako místo podnikání (sídlo).',
        type: 'text',
        refer: 'documentProcessor-city',
        required: true,
        state: 'city',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor',
        parentProperty: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'documentProcessor-cityPart',
        required: false,
        state: 'cityPart',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor',
        parentProperty: 'address'

    },
    {
        id: 3,
        label: 'Ulice (nepovinné)',
        type: 'text',
        refer: 'documentProcessor-street',
        required: false,
        state: 'street',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor',
        parentProperty: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné (nepovinné)',
        type: 'text',
        refer: 'documentProcessor-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor',
        parentProperty: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'documentProcessor-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor',
        parentProperty: 'address'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'documentProcessor-zipCode',
        required: true,
        state: 'zip',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor',
        parentProperty: 'address'
    },
];

const DOCUMENT_PROCESSOR_PERSON_AUTHORIZED =  [
    {
        id: 1,
        label: 'Jméno',
        type: 'text',
        refer: 'documentProcessor-personAuthorized-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'names',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 2,
        label: 'Příjmení',
        type: 'text',
        refer: 'documentProcessor-personAuthorized-surname',
        required: true,
        state: 'lastName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 3,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'documentProcessor-personAuthorized-birthSurname',
        required: false,
        state: 'lastNameOrigin',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 4,
        label: 'Datum narození (nepovinné)',
        type: 'date',
        refer: 'documentProcessor-personAuthorized-date',
        required: false,
        state: 'dateOfBirth',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 5,
        label: 'Státní příslušnost',
        type: 'select',
        refer: 'documentProcessor-personAuthorized-nationality',
        required: true,
        state: 'nationality',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 6,
        label: 'Funkce nebo oprávnění',
        info: 'Je nutné doložit příslušný doklad o pověření.',
        type: 'text',
        refer: 'documentProcessor-personAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 7,
        label: 'Oprávnění jednat od',
        type: 'date',
        refer: 'documentProcessor-personAuthorized-from',
        required: true,
        state: 'mandateFrom',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 8,
        label: 'Oprávnění jednat do (nepovinné)',
        type: 'date',
        refer: 'documentProcessor-personAuthorized-to',
        required: false,
        state: 'mandateTO',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
];

const DOCUMENT_PROCESSOR_CONTACTS = [
    {
        id: 1,
        label: 'Telefon (nepovinné)',
        type: 'text',
        refer: 'documentProcessor-tel',
        required: false,
        state: 'phone',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 2,
        label: 'E-mail (nepovinné)',
        type: 'text',
        refer: 'documentProcessor-email',
        required: false,
        state: 'email',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 3,
        label: 'ID datové schránky',
        type: 'text',
        refer: 'documentProcessor-idDataBox',
        required: false,
        state: 'idDataBox',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
];
const DOCUMENT_PROCESSOR_CONTACTS_PO = [
    {
        id: 1,
        label: 'Telefon (nepovinné)',
        type: 'text',
        refer: 'documentProcessorPO-tel',
        required: false,
        state: 'phone',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 2,
        label: 'E-mail (nepovinné)',
        type: 'text',
        refer: 'documentProcessorPO-email',
        required: false,
        state: 'email',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
    {
        id: 3,
        label: 'ID datové schránky',
        type: 'text',
        refer: 'documentProcessorPO-idDataBox',
        required: false,
        state: 'idDataBox',
        object: 'accompanyingDocument',
        propertyToUpdate: 'documentProcessor'
    },
];

const BUILDING_CONTRACTOR_BUSINESS =  [
    {
        id: 1,
        label: 'IČO (nepovinné)',
        type: 'text',
        refer: 'buildingContractorBusiness-ico',
        info: 'Uveďte identifikační číslo osoby (IČO), bylo-li přiděleno.',
        required: false,
        state: 'ico',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 2,
        label: 'Jméno (nepovinné)',
        type: 'text',
        refer: 'buildingContractorBusiness-name',
        info: 'Uveďte všechna jména',
        required: false,
        state: 'names',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 3,
        label: 'Příjmení (nepovinné)',
        type: 'text',
        refer: 'buildingContractorBusiness-surname',
        required: false,
        state: 'lastName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 4,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'buildingContractorBusiness-birthSurname',
        info: 'Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení',
        required: false,
        state: 'lastNameOrigin',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
];

const BUILDING_CONTRACTOR_PO =  [
    {
        id: 1,
        label: 'IČO (nepovinné)',
        info: 'Uveďte identifikační číslo osoby (IČO), bylo-li přiděleno.',
        type: 'text',
        refer: 'buildingContractorPO-ico',
        required: false,
        state: 'ico',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 2,
        label: 'Název nebo obchodní firma (nepovinné)',
        type: 'text',
        refer: 'buildingContractorPO-firm',
        required: false,
        state: 'poName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
];

const BUILDING_CONTRACTOR_LOCATION = [
    {
        id: 1,
        label: 'Obec (nepovinné)',
        info: 'Uveďte adresu zapsanou v obchodním rejstříku nebo jiné zákonem upravené evidenci jako místo podnikání (sídlo).',
        type: 'text',
        refer: 'buildingContractor-city',
        required: false,
        state: 'city',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor',
        parentProperty: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'buildingContractor-cityPart',
        required: false,
        state: 'cityPart',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor',
        parentProperty: 'address'

    },
    {
        id: 3,
        label: 'Ulice (nepovinné)',
        type: 'text',
        refer: 'buildingContractor-street',
        required: false,
        state: 'street',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor',
        parentProperty: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné (nepovinné)',
        type: 'text',
        refer: 'buildingContractor-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor',
        parentProperty: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'buildingContractor-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor',
        parentProperty: 'address'
    },
    {
        id: 6,
        label: 'PSČ (nepovinné)',
        type: 'text',
        refer: 'buildingContractor-zipCode',
        required: false,
        state: 'zip',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor',
        parentProperty: 'address'
    },
];

const BUILDING_CONTRACTOR_PERSON_AUTHORIZED =  [
    {
        id: 1,
        label: 'Jméno',
        type: 'text',
        refer: 'buildingContractor-personAuthorized-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'names',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 2,
        label: 'Příjmení',
        type: 'text',
        refer: 'buildingContractor-personAuthorized-surname',
        required: true,
        state: 'lastName',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 3,
        label: 'Rodné příjmení',
        type: 'text',
        refer: 'buildingContractor-personAuthorized-birthSurname',
        required: false,
        state: 'lastNameOrigin',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 4,
        label: 'Datum narození',
        type: 'date',
        refer: 'buildingContractor-personAuthorized-date',
        required: true,
        state: 'dateOfBirth',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 5,
        label: 'Funkce nebo oprávnění',
        info: 'Je nutné doložit příslušný doklad o pověření.',
        type: 'text',
        refer: 'buildingContractor-personAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 6,
        label: 'Oprávnění jednat od',
        type: 'date',
        refer: 'buildingContractor-personAuthorized-from',
        required: true,
        state: 'mandateFrom',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 7,
        label: 'Oprávnění jednat do',
        type: 'date',
        refer: 'buildingContractor-personAuthorized-to',
        required: true,
        state: 'mandateTO',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
];

const BUILDING_CONTRACTOR_CONTACTS = [
    {
        id: 1,
        label: 'Telefon (nepovinné)',
        type: 'text',
        refer: 'buildingContractor-tel',
        required: false,
        state: 'phone',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 2,
        label: 'E-mail (nepovinné)',
        type: 'text',
        refer: 'buildingContractor-email',
        required: false,
        state: 'email',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
    {
        id: 3,
        label: 'ID datové schránky',
        type: 'text',
        refer: 'buildingContractor-idDataBox',
        required: false,
        state: 'idDataBox',
        object: 'accompanyingDocument',
        propertyToUpdate: 'buildingContractor'
    },
];


export {
    ADDITION_BUILDING_CONT,
    ADDITION_PROCESSOR,
    DOCUMENT_PROCESSOR_BUSINESS,
    DOCUMENT_PROCESSOR_PO,
    DOCUMENT_PROCESSOR_LOCATION,
    DOCUMENT_PROCESSOR_PERSON_AUTHORIZED,
    DOCUMENT_PROCESSOR_CONTACTS,
    DOCUMENT_PROCESSOR_CONTACTS_PO,
    BUILDING_CONTRACTOR_BUSINESS,
    BUILDING_CONTRACTOR_PO,
    BUILDING_CONTRACTOR_LOCATION,
    BUILDING_CONTRACTOR_PERSON_AUTHORIZED,
    BUILDING_CONTRACTOR_CONTACTS,
    items,
};