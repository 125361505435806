import axios from 'axios';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { iconChange, trashbin } from '../../../assets';
import { 
    DIVISION,
    UNIFICATION,
    CADASTRAL_AREA,
    CHANGE_OF_USE,
    CONSTRUCTION_SITE,
    DIVISION_UNIFICATION,
    PARCEL_NUMBER,
    PROTECTION,
    SURFACE_AREA,
    TYPE,
    ACTUAL_PARCEL_USAGE,
    NEW_PARCEL_USAGE,
    AFFECTED_POZEMKY_TABLE,
    POZEMKY_TABLE
} from '../../../constants/sharedConstants.js';
import { Container } from 'react-bootstrap';
import '../../../css/formTableView.css';
import { ButtonsAndPaginate } from '../../../components/index.js';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { enterCsvData, getFormType, removeCsvData, setCsvDataAndRequest, shouldNotSaveTable, handleAddManuallyIntoTables, getAnoNeValue } from '../../../helperFunctions/helpers.js';
import { formSave } from '../../../apiCalls/formApiCalls.js';
import FormContext from '../../../formContexts/FormContext.js';

const PozemkyTable = ({
    progressElements,
    setProgressElements,
    urlPath,
    title,
    subtitle,
    isAffected,
    dataToAdd,
    receivedToken,
    newBuilding,
    setNewBuilding,
    setDataToAdd
}) => {
    const [showPozemekModal, setShowPozemekModal] = useState(false);
    const resultPerPage = 25;
    const [listData, setListData] = useState([]);
    const { token } = useContext(AuthContext);
    const [startIndex, setStartIndex] = useState(0);
    const endIndex = startIndex + resultPerPage;
    const {id} = useContext(FormContext);
    const [tableRequest, setTableRequest] = useState({
        'applicationId': id,
    });
    const formType = getFormType(urlPath);
    const currentPageRef = useRef(null);
    const [currentlyShowing, setCurrentlyShowing] = useState(0);
    const dynamicKey = isAffected ? 'affectedBuildParcels' : 'buildParcels';
    const preloadRef = useRef(false);
    const [itemToUpdate, setItemToUpdate] = useState(null);
    const is16Aff = formType === 16 && isAffected;
    const isForm15 = formType === 15;
    const is15Aff = isForm15 && isAffected;
    // const is15NotAff = isForm15 && !isAffected;
    const propertyToUpdate = isAffected ? 'affectedBuildParcels' : 'buildParcels';

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(tableRequest, token, urlPath, source, receivedToken ?? undefined);
    };

    useEffect(() => {
        if (!dataToAdd) {
            return;
        }

        if (dataToAdd.length > 0) {
            preloadRef.current = true;
        }

        setCsvDataAndRequest(
            listData,
            setListData,
            setTableRequest,
            dynamicKey,
            dataToAdd
        );

    }, [dataToAdd]);

    useEffect(() => {
        if (shouldNotSaveTable(tableRequest)) {
            return;
        }

        if (preloadRef.current) {
            preloadRef.current = false;
            return;
        }

        if (setDataToAdd) setDataToAdd(prev => ({...prev, [propertyToUpdate]: [...listData]}));

        saveChanges();
    }, [tableRequest]);

    const handleAddPozemek = (pozemek) => {
        const newData = [...listData, 
            {...pozemek, uid: uuidv4()}
        ];
        setListData(newData);
        handleAddManuallyIntoTables(setTableRequest, listData, pozemek, dynamicKey, '');
        if(!setDataToAdd) return;
        setDataToAdd(prev => ({...prev, [propertyToUpdate]: newData}));
    };

    const handleUpdatePozemek = (pozemek) => {
        const newData = [...listData].map(data => {
            if (data.uid === pozemek.uid) {
                return {...data, ...pozemek};
            }
            return data;
        });
        setListData(newData);
        handleAddManuallyIntoTables(setTableRequest, listData, pozemek, dynamicKey, pozemek.uid);
        if(!setDataToAdd) return;
        setDataToAdd(prev => ({...prev, [propertyToUpdate]: newData}));
    };

    const handleRemovePozemek = (keyStr) => {
        const newData = listData.filter(item => String(item.uid) !== String(keyStr));
        setListData(newData);
        removeCsvData(setTableRequest, newData, dynamicKey);
        if(!setDataToAdd) return;
        setDataToAdd(prev => ({...prev, [propertyToUpdate]: newData}));
    };

    const handleUpdate = (data) => {
        setItemToUpdate(data);
        setShowPozemekModal(true);
    };

    const trimValue = (value) => value?.trim() ?? '';

    const handleCsv = async (data) => {
        const newLines = [];

        await data.forEach((line, index) => {
            let newLine = {};

            if (index === 0) {
                return;
            }

            if (line.some(el => el)) {
                const commonLines = {
                    ...(!isForm15 && {constructionId: trimValue(line[0])}), // "string"
                    cadastralTerritory: trimValue(line[(isForm15) ? 0 : 1] ), // "string",
                    cadastralTerritoryCode: trimValue(line[(isForm15) ? 1 : 2]), // "string",
                    parcelNumber: trimValue(line[(isForm15) ? 2 : 3]), // "string",
                    ...(!is15Aff && {type: trimValue(line[(isForm15) ? 3 : 4])}), // "string",
                    ...(!is15Aff && {area: trimValue(line[(isForm15) ? 4 : 5])}), // "string",
                };

                if (formType === 8 || formType === 10 || isForm15) {
                    newLine = {
                        ...commonLines,
                        parcelType: trimValue(line[is15Aff ? 3 : isForm15 ? 5 : 6]), // "string"
                        projectId: trimValue(line[is15Aff ? 4 : isForm15 ? 6 : 7]), // "string"
                        projectName: trimValue(line[is15Aff ? 5 : isForm15 ? 7 : 8]), // "string"
                    };
                } else if (formType === 7) {
                    newLine = {
                        ...commonLines,
                        division: getAnoNeValue(line[6]), // bool
                        fusion: getAnoNeValue(line[7]), // bool
                        parcelType: trimValue(line[8]), // "string"
                        projectId: trimValue(line[9]), // "string"
                        projectName: trimValue(line[10]), // "string"
                    };
                } else if (formType === 6) {
                    newLine = {
                        ...commonLines,
                        parcelType: trimValue(line[6]), // "string"
                        projectId: trimValue(line[7]), // "string"
                        projectName: trimValue(line[8]), // "string"
                        actualParcelUsage: trimValue(line[9]), // "string"
                        newParcelUsage: trimValue(line[10]), // "string"
                    };
                } else if (formType === 1 || formType === 2 || formType === 4 || formType === 9 || formType === 16 || formType === 17 || formType === 18) {
                    newLine = {
                        ...commonLines,
                        changeOfUse: getAnoNeValue(line[6]), // bool
                        divisionOfFusion: getAnoNeValue(line[7]), // bool
                        protectionZone: getAnoNeValue(line[8]), // bool
                        constructionSite: getAnoNeValue(line[9]), // bool
                        parcelType: trimValue(line[10]), // "string"
                        projectId: trimValue(line[11]), // "string"
                        projectName: trimValue(line[12]), // "string"
                    };
                } else {
                    newLine = {
                        ...commonLines,
                        changeOfUse: getAnoNeValue(line[6]), // bool
                        divisionOfFusion: getAnoNeValue(line[7]), // bool
                        division: getAnoNeValue(line[8]), // bool
                        fusion: getAnoNeValue(line[9]), // bool
                        protectionZone: getAnoNeValue(line[10]), // bool
                        constructionSite: getAnoNeValue(line[11]), // bool
                        parcelType: trimValue(line[12]), // "string"
                        projectId: trimValue(line[13]), // "string"
                        projectName: trimValue(line[14]), // "string"
                        actualParcelUsage: trimValue(line[15]), // "string"
                        newParcelUsage: trimValue(line[16]), // "string"
                    };
                }
            }

            newLines.push(newLine);
        });

        setListData([...newLines.map(el => ({
            ...el,
            uid: uuidv4()
        }))]);

        enterCsvData(setTableRequest, newLines, dynamicKey);
    };

    useEffect(() => {
        if (listData.length === 0 || !currentPageRef.current) {
            return;
        }

        setCurrentlyShowing(currentPageRef.current.childNodes.length);
    }, [listData.length, startIndex]);

    return (
        <Container className='py-3' fluid>
            <h2 className='m-0 pb-2'>{isAffected ? 'Sousední pozemky' : (title || 'Pozemky')}</h2>
            {subtitle && <p className='p-0 mb-4 table-subtitle'>{subtitle}</p>}
            <Container className='overflow-x-scroll' fluid>
                {(listData.length > 0) &&
                    <table className='border my-3' >
                        <thead>
                            <tr>
                                <th></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${CADASTRAL_AREA}`}</p></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${PARCEL_NUMBER}`}</p></th>
                                {
                                    (!is16Aff && !is15Aff) &&
                                    <>
                                        <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${TYPE}`}</p></th>
                                        <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${SURFACE_AREA} (m²)`}</p></th>
                                    </>
                                }
                                {(formType !== 7 && formType !== 8 && formType !== 10 && formType !== 6 && formType !== 15 && !is16Aff) && 
                                <>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${CHANGE_OF_USE}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${DIVISION_UNIFICATION}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${PROTECTION}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${CONSTRUCTION_SITE}`}</p></th>
                                </>}
                                {formType === 7 &&
                                <>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${DIVISION}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${UNIFICATION}`}</p></th>
                                </>}
                                {formType === 6 &&
                                <>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${ACTUAL_PARCEL_USAGE}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${NEW_PARCEL_USAGE}`}</p></th>
                                </>}
                                {/* <th className='py-2 px-3'></th> */}
                                <th className='py-2 px-3'></th>
                            </tr>
                        </thead>
                        <tbody ref={currentPageRef}>
                            {listData.length > 0 && listData.map((data, index, array) => {
                                if ((array.length <= resultPerPage) || (index >= startIndex && index < endIndex)) {
                                    return (
                                        <tr key={data.uid}>
                                            <td className='py-2 px-3'><p className='row-number'>{index + 1}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.cadastralTerritory ?? ''}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.parcelNumber ?? ''}</p></td>
                                            {
                                                (!is16Aff && !is15Aff) &&
                                                <>
                                                    <td className='py-2 px-3'><p className='row-info py-2'>{data?.type ?? ''}</p></td>
                                                    <td className='py-2 px-3'><p className='row-info py-2'>{data?.area ?? ''}</p></td>
                                                </>
                                            }
                                            {(formType !== 7 && formType !== 8 && formType !== 10 && formType !== 6 && formType !== 15 && !is16Aff) &&
                                            <>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.changeOfUse === true ? 'Ano' : 'Ne'}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.divisionOfFusion === true ? 'Ano' : 'Ne'}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.protectionZone === true ? 'Ano' : 'Ne'}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionSite === true ? 'Ano' : 'Ne'}</p></td>
                                            </>}
                                            {formType === 7 &&
                                            <>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.division === true ? 'Ano' : 'Ne'}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.fusion === true ? 'Ano' : 'Ne'}</p></td>
                                            </>}
                                            {formType === 6 &&
                                            <>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.actualParcelUsage ?? ''}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.newParcelUsage ?? ''}</p></td>
                                            </>}
                                            <td className='py-2 px-3'>                                            
                                                <button 
                                                    type='button'
                                                    onClick={() => handleUpdate(data)}
                                                    className='d-flex justify-content-center align-items-center table-button-upravit'
                                                >
                                                    <img src={iconChange} alt='upravit' className='pe-2' />
                                                    Upravit
                                                </button>
                                            </td>
                                            <td className='py-2 px-3'>
                                                <button
                                                    type='button'
                                                    onClick={() => handleRemovePozemek(data.uid)}
                                                    className='d-flex justify-content-center align-items-center table-button-smazat'
                                                >
                                                    <img src={trashbin} alt='trashbin' className='pe-2' />
                                                        Smazat
                                                </button>
                                            </td>
                                        </tr>);
                                }
                            })}
                        </tbody>
                    </table>
                }
            </Container>
            <ButtonsAndPaginate
                showPozemekModal={showPozemekModal}
                setShowPozemekModal={setShowPozemekModal}
                itemToUpdate={itemToUpdate}
                setItemToUpdate={setItemToUpdate}
                newBuilding={newBuilding}
                setNewBuilding={setNewBuilding}
                category={isAffected ? AFFECTED_POZEMKY_TABLE : POZEMKY_TABLE}
                listData={listData}
                setListData={setListData}
                startIndex={startIndex}
                setStartIndex={setStartIndex}
                progressElements={progressElements}
                setProgressElements={setProgressElements}
                handleCsv={handleCsv}
                setTableRequest={setTableRequest}
                resultPerPage={resultPerPage}
                currentlyShowing={currentlyShowing}
                isAffected={isAffected}
                handleAddItem={handleAddPozemek}
                handleUpdateItem={handleUpdatePozemek}
            />
        </Container>
    );
};

PozemkyTable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    handleCsv: PropTypes.func,
    
    urlPath: PropTypes.string,
    isAffected: PropTypes.bool,
    dataToAdd: PropTypes.arrayOf(Object),
    setDataToAdd: PropTypes.func,
    receivedToken: PropTypes.string,
    newBuilding: PropTypes.object,
    setNewBuilding: PropTypes.func,
};

export default PozemkyTable;