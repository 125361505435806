import React, { useEffect, useState } from 'react';
import '../css/reusable.css';
import { Container } from 'react-bootstrap';
import { FormFooter, FormHeader, Form02MainSection, FormFinisher } from '../components';
import { useGlobalState } from '../constants/states';
import { getTotalValueFromUploads, isNotUploading, shouldNotUpdateExceptions, trackUploads, getDocumentsToTrack } from '../helperFunctions/helpers';
import { getItemsBasedOnCheckbox, items } from '../constants/form02';
import { FORM_02_INTENTION, DOCUMENT_FIRST, DOCUMENT_SECOND, DOCUMENT_THIRD_1, DOCUMENT_THIRD_2, DOCUMENT_THIRD_3, DOCUMENT_THIRD_4, DOCUMENT_THIRD_5, DOCUMENT_FOURTH, DOCUMENT_FIFTH } from '../constants/sharedConstants';
import usePrevious from '../components/CustomHooks/usePrevious';
import FormContext from '../formContexts/FormContext';

const Form02 = () => {
    const [state, setState] = useGlobalState();
    const [progressElements, setProgressElements] = useState([]);
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth < 992);
    const [isFinished, setIsFinished] = useState(false);
    const [id, setId] = useState(null);
    const [uploadError, setUploadError] = useState([]);
    const [isSuccessOrFailSubmission, setIsSuccessOrFailSubmission] = useState(null);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [stagesArr, setStagesArr] = useState(items);
    const [ fetch, setFetch ] = useState(false);
    const [documents, setDocuments] = useState({
        [DOCUMENT_FIRST]: [],
        [DOCUMENT_SECOND]: [],
        [DOCUMENT_THIRD_1]: [],
        [DOCUMENT_THIRD_2]: [],
        [DOCUMENT_THIRD_3]: [],
        [DOCUMENT_THIRD_4]: [],
        [DOCUMENT_THIRD_5]: [],
        [DOCUMENT_FOURTH]: [],
        [DOCUMENT_FIFTH]: [],
    });
    const dokumentaceProgressElements = [...(progressElements || [])].filter(el => el.abortController);
    const currentlyUploading = dokumentaceProgressElements.filter(el => (!el.success && !el.message) && (el.progress > 0 && el.progress < 100));
    const averageProgress = currentlyUploading.length > 0 ? Math.round(getTotalValueFromUploads(currentlyUploading) / currentlyUploading.length) : 0;
    const documentsToTrack = getDocumentsToTrack(documents);
    
    useEffect(() => {
        if (documentsToTrack.length === 0) {
            return;
        }
            
        trackUploads(setProgressElements, documentsToTrack);
    }, [documentsToTrack.length]);

    useEffect(() => {
        function handleBeforeUnload (e) {
            if (!isNotUploading(progressElements)) {
                e.preventDefault();
            }
        }

        function handleResize() {
            if(window.innerWidth < 992) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('resize', handleResize);
        };
    }, [progressElements]);

    const { stage, checkbox } = state;
    const prevChecboxState = usePrevious(checkbox);

    const setCheckbox = (newCheckbox) => {
        setState({ ...state, checkbox: newCheckbox });
    };

    const setStage = (newStage) => {
        setState({ ...state, stage: newStage });
    };

    useEffect(() => {
        if (shouldNotUpdateExceptions(checkbox)) {
            return;
        }
        
        if (!prevChecboxState) {
            return;
        }

        getItemsBasedOnCheckbox(checkbox.first, checkbox.second, setStagesArr, prevChecboxState);

    }, [checkbox]);

    useEffect(() => {
        return () => {
            setCheckbox({
                first: null,
                second: null,
            });
            setStage(1);
        };
    }, []);

    const validationFunctionRef = React.useRef();
    const saveFormRef = React.useRef();

    return (
        <Container className={`d-flex flex-column align-items-center pt-5 background ${isMobileView ? 'mobile' : ''}`} fluid>
            {isFinished ? <FormFinisher isSuccessOrFailSubmission={isSuccessOrFailSubmission} header={FORM_02_INTENTION.title} id={id} linkRef={'form02'}
                setStage={setStage} setIsFinished={setIsFinished} /> :
                <>
                    <FormContext.Provider
                        value={{
                            intention: FORM_02_INTENTION.title,
                            stepValue: stage,
                            validationRef: validationFunctionRef,
                            setStagesArr,
                            stagesArr,
                            uploadError,
                            setUploadError,
                            uploadedBytes,
                            setUploadedBytes,
                            saveFormRef,
                            id,
                            setId,
                            fetch,
                            setFetch
                        }}
                    >
                        <FormHeader averageProgress={averageProgress} currentlyUploading={currentlyUploading} isMobileView={isMobileView} header={FORM_02_INTENTION} />
                        <Form02MainSection documents={documents} setDocuments={setDocuments} isMobileView={isMobileView} checkbox={checkbox} setCheckbox={setCheckbox} stage={stage} progressElements={progressElements} setProgressElements={setProgressElements} />
                        <FormFooter currentlyUploading={currentlyUploading} averageProgress={averageProgress} setIsSuccessOrFailSubmission={setIsSuccessOrFailSubmission} formNumber={'02'} setIsFinished={setIsFinished} />
                    </FormContext.Provider>
                </>}
        </Container>
    );
};

export default Form02;