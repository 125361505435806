import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext.js';
import { shouldNotSave,  spreadObject, hasZamerError, setUploadedDocumentsSizeFromZamer } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import ZamerSelectionControls from '../ZamerSelectionControls.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const buildIntentionModel = {
    title: { value: null, dirty: false },
};

const ZamerComponent11 = ({ setCheckbox, urlPath, progressElements }) => {
    const {intention, stepValue, validationRef, setStagesArr, saveFormRef, id, setUploadedBytes} = useContext(FormContext); 
    
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);
    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const [loading, setLoading] = useState(false);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest), ...((formData && formData.id) ? { form: { id: formData.id } } : {}) }));

                        setCheckbox({
                            first: intention?.carriedWithDeviations ?? false,
                        });

                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value, 
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    const documentations = response.data?.buildApplication?.documentations ?? null;
                    if (documentations && !progressElements?.length) setUploadedDocumentsSizeFromZamer(documentations, setUploadedBytes);
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    const buildIntention = request?.buildIntention;

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, buildIntention)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls 
                        request={request} 
                        setRequest={setRequest} 
                        state={state} 
                        setState={setState} 
                        buildIntentionModel={buildIntentionModel} 
                        textNovy={'zadat ručně'} 
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                    />

                </Container>}
        </>
    );
};

ZamerComponent11.propTypes = {
    
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent11;