import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NewConstructionModal from '../../NewConstructionModal';
import PropTypes from 'prop-types';
import FormContext from '../../../../formContexts/FormContext';
import { FORM_12_INTENTION, FORM_13_INTENTION } from '../../../../constants/sharedConstants';

const DetailsFromStavbaComponent = ({
    newBuilding,
    handleShow,
    show,
    setShow,
    handleClose,
    setNewBuilding,
    setRequest,
    request,
}) => {
    const {intention} = useContext(FormContext);
    const canShowTitle = intention === FORM_12_INTENTION.title || intention === FORM_13_INTENTION.title;
    return (
        <>
            {canShowTitle && <Row>
                <p className='pt-5 pb-3 px-0 pridat-text'>Další údaje o stavbě</p>
            </Row>}

            <Row className='row-wrapper m-0 pb-4'>
                <Col md={6}>
                    <p className='py-3 new-construction-help-text'>Druh a účel stavby</p>
                                               
                    <label className='id-checkbox-label'>
                        <input type="text" value={newBuilding?.select ?? ''} className='new-construction-input' disabled/>
                        <button type='button' className='d-flex justify-content-center align-items-center new-construction-button' onClick={() => handleShow(setShow)}>Vybrat</button>
                    </label>
                </Col>
                <NewConstructionModal
                    show={show} 
                    setShow={setShow}
                    handleClose={handleClose}
                    setNewBuilding={setNewBuilding} 
                    setRequest={setRequest}
                    object={'requestObject'}
                    propertyToUpdate={'constructionType'}
                />
            </Row>

            <label className='d-flex flex-column label pb-4'>
                <Container fluid >
                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                        Účel užívání
                        <input
                            type={'text'}
                            className='id-input2'
                            list='browsed_construction_list'
                            value={request.usePurpose ?? ''}
                            onChange={(e) => setRequest(prev => ({...prev, usePurpose: e.target.value}))}
                        />
                    </Col>
                </Container>
            </label>

            <Row>
                <label className='p-0 id-checkbox-label pb-3'>
                    <input type="checkbox" className='id-checkbox-input' checked={Boolean(request.temporary)}
                        onChange={(e) => setRequest(prev => ({...prev, temporary: e.target.checked}))} />
                        Stavba dočasná
                </label>
            </Row>

            <label className='d-flex flex-column label pb-3'>
                <Container fluid >
                    <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                        Počet bytů
                        <input
                            type={'text'}
                            className='id-input2'
                            list='browsed_construction_list'
                            value={request.numberOfApartments ?? ''}
                            onChange={(e) => setRequest(prev => ({...prev, numberOfApartments: e.target.value}))}
                        />
                    </Col>
                </Container>
            </label>

            <Row className='m-0 py-3'>
                <Col xs={12} lg={6} className='d-flex'>
                    <label className='d-flex'>
                        <input
                            className='me-1'
                            type="checkbox"
                            name="changeOfUse"
                            checked={Boolean(request.partOfConstruction)}
                            onChange={(e) => setRequest(prev => ({...prev, partOfConstruction: e.target.checked }))}
                        />
                        Část stavby
                    </label>
                </Col>
                <Col xs={12} lg={6} className='input-help-text mt-1 ps-lg-3'>
                                    Zaškrtněte v případě, že je požadována kolaudace části stavby schopná samostatného užívání.
                </Col>
            </Row>

            <label className='d-flex flex-column label pb-4'>
                <Container fluid >
                    <Row className=''>
                        <Col xs={12} lg={6} className='d-flex flex-column modal-input-style'>
                        IČS (nepovinné)
                            <input
                                type={'text'}
                                className='id-input2'
                                value={request.constructionId ?? ''}
                                onChange={(e) => setRequest(prev => ({...prev, constructionId: e.target.value}))}
                            />
                        </Col>
                        <Col xs={12} lg={6} className='input-help-text d-flex align-items-center ps-lg-3 mt-2'>Uveďte IČS, pokud již bylo stavbě přiděleno.</Col>
                    </Row>
                </Container>
            </label>

            {intention !== FORM_13_INTENTION.title && <> 
                <Row>
                    <Col xs={12} lg={6} className='modal-input-style'>
                Údaj o poloze definičního bodu stavby, a jde-li o budovu, údaj o adresním místě
                    </Col>
                    <Row>
                        <Col xs={12} lg={6} className='d-flex flex-column mb-4 modal-input-style'>
                            <input
                                type="text"
                                className='id-input2'
                                value={request.location ?? ''}
                                list='other'
                                onChange={(e) => setRequest(prev => ({...prev, location: e.target.value}))}
                            />
                        </Col>
                        <Col xs={12} lg={6} className='input-help-text mt-1 ps-lg-3'>
                    Budova - § 3 odst. 1 písm. a) bod 1 zákona č. 151/1997 Sb., zákon o oceňování majetku, Adresní místo - Zákon č. 111/2009 Sb., o základních registrech.
                        </Col>
                    </Row>
                </Row>

                <Row>
                    <Col xs={12} lg={6} className='modal-input-style'>
                Klasifikační třída podle Průkazu energetické náročnosti budovy, je-li vyžadován jiným právním předpisem
                    </Col>
                    <Row>
                        <Col xs={12} lg={6} className='d-flex flex-column mb-4 modal-input-style'>
                            <input
                                type="text"
                                className='id-input2'
                                value={request.classification ?? ''}
                                list='other'
                                onChange={(e) => setRequest(prev => ({...prev, classification: e.target.value}))}
                            />
                        </Col>
                        <Col xs={12} lg={6} className='input-help-text mt-1 ps-lg-3'>
                    Zákon č. 406/2000 Sb., o hospodaření energií
                        </Col>
                    </Row>
                </Row>
            </>}
        </>
    );
};

DetailsFromStavbaComponent.propTypes = {
    newBuilding: PropTypes.any,
    handleShow: PropTypes.func,
    show: PropTypes.bool,
    setShow: PropTypes.func,
    handleClose: PropTypes.func,
    setNewBuilding: PropTypes.func,
    setRequest: PropTypes.func,
    request: PropTypes.object,
};

export default DetailsFromStavbaComponent;